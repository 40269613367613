<div class="page-container rep-review-container container">
  <div class="breadcrumb">
    <button
      mat-button
      (click)="nav('unified')"
      *hasPermission="'!ToolsLandingPage'"
    >
      <mat-icon>home</mat-icon>
      <span class="text-container">Home</span>
    </button>
    <button
      mat-button
      (click)="nav('tools')"
      *hasPermission="'ToolsLandingPage'"
    >
      <mat-icon>home</mat-icon>
      <span class="text-container">Home</span>
    </button>
    /
    <button mat-button (click)="nav('post-trade/RR')">
      <span class="text-container">Post Transaction</span>
    </button>
    /
    <button mat-button (click)="nav('rep-review')" [disabled]="repId === ''">
      <span class="text-container">Financial Professional Monitor</span>
    </button>
    @if (repId !== '') {
      <span>/</span>
      <button mat-button (click)="nav('rep-review')" [disabled]="repId !== ''">
        <span class="text-container">Financial Professional Detail</span>
      </button>
    }
  </div>

  @if (repId === '') {
    <app-rep-review-grid></app-rep-review-grid>
  } @else {
    <app-rep-review-detail [repId]="repId"></app-rep-review-detail>
  }
</div>
