import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionStorageService } from '../services/session-storage.service';
import { EnvironmentService } from '../services/environment.service';
import { ErrorReportingService } from '../services/error-reporting.service';
import { RightBridgeApiService } from '../services/right-bridge-api.service';

@Injectable()
export class PostTradeService {
  private environment;
  public baseUrl: string;
  public f5500BaseUrl: string;
  private org: string;

  constructor(
    private http: HttpClient,
    private ss: SessionStorageService,
    private envSvc: EnvironmentService,
    private rbs: RightBridgeApiService,
    private errSvc: ErrorReportingService
  ) {
    this.environment = this.envSvc.get();

    if (!this.environment) {
      this.envSvc.loadSettings();
      this.environment = this.envSvc.get();
    }

    this.f5500BaseUrl = this.environment.f5500Base;
    this.baseUrl = this.environment.apiBase;
    this.org = this.environment.org;
    this.org = this.environment.org || 'basev2';
  }

  getPostGridSetup() {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + `/PostTrade/TradeGridSetup?firmid=a`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getPostTradeGridData(queryData?) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    let url = this.baseUrl + `/PostTrade/TradeGrid`;

    if (queryData) {
      url = url + `?query=${queryData}`;
    }

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  generatePostTradeReviewReport(
    gridType,
    fileName,
    query,
    columns,
    rows,
    category
  ) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    const endpoint =
      gridType.toLowerCase() === 'account'
        ? `/Account/AccountGridReport`
        : `/PostTrade/${gridType}GridReport`;
    const url =
      this.baseUrl +
      `${endpoint}?fileName=${fileName}&query=${query}&columns=${columns}&maxRows=${rows}&category=${category} Monitor`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getPostTradeDetail(id) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + `/PostTrade/Detail?id=${id}`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getTradeGridSummary() {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + `/PostTrade/TradeGridSummary`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  updateTrade(data) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'cm')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + `/PostTrade/UpdateTradeRecord`;

    return this.http.post(url, data, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getAccountGridSetup() {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + `/Account/GridSetup?firmid=a`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getAccountGridData(queryData) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    let url = this.baseUrl + `/Account/AccountGrid`;
    if (queryData) {
      url = url + `?query=${queryData}`;
    }
    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getAccountDetail(id) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'cm')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + `/Account/Detail?accountID=${id}`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getAccountGridSummary() {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'cm')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + `/Account/AccountGridSummary`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  updateAccount(data) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'cm')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + `/Account/UpdateAccount`;

    return this.http.post(url, data, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getUserReportsList() {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + `/PostTrade/UserReportsList`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  downloadUserReport(id) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org)
      .set('accept', 'application/zip');
    const url = this.baseUrl + `/PostTrade/GetFile?ID=${id}`;

    return this.http
      .get(url, {
        headers: reqHeaders,
        observe: 'response',
        responseType: 'blob',
      })
      .pipe(
        map(res => {
          return res.body;
        }),
        catchError(error => {
          return this.errSvc.handleError(error);
        })
      );
  }

  getRepGridSetup() {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + `/PostTrade/RepGridSetup?firmid=a`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getRepGridData(queryData) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    let url = this.baseUrl + `/PostTrade/RepGrid`;
    if (queryData) {
      url = url + `?query=${queryData}`;
    }
    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  updateRep(data) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'cm')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + `/PostTrade/UpdateRepRecord`;

    return this.http.post(url, data, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getRepDetail(id) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'cm')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + `/PostTrade/RepDetail?id=${id}`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getRepGridSummary() {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + `/PostTrade/RepGridSummary`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getAgGridSetup() {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    const url =
      this.baseUrl + `/Admin/UIConfigHeaders?uiconfig=ag-grids&type=trades`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getAccountGroupingGridData(queryData?) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + `/AccountGroup/SearchGroup`;

    // if (queryData) {
    //   url = url + `?query=${queryData}`;
    // }

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getAccountGroupingDetail(id) {
    const sessId = this.ss.get('session');
    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sessId)
      .set('X-RB-I', 'rb')
      .set('X-RB-O', this.org);
    const url = this.baseUrl + `/AccountGroup/Details?id=${id}`;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError(error => {
        return this.errSvc.handleError(error);
      })
    );
  }
}
