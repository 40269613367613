<div class="page-container account-grouping-container container">
  <div class="breadcrumb">
    <button
      mat-button
      (click)="nav('unified')"
      *hasPermission="'!ToolsLandingPage'"
    >
      <mat-icon>home</mat-icon>
      <span class="text-container">Home</span>
    </button>
    <button
      mat-button
      (click)="nav('tools')"
      *hasPermission="'ToolsLandingPage'"
    >
      <mat-icon>home</mat-icon>
      <span class="text-container">Home</span>
    </button>
    /
    <button mat-button (click)="nav('post-trade')">
      <span class="text-container">Post Transaction</span>
    </button>
    /
    <button
      mat-button
      (click)="nav('account-grouping')"
      [disabled]="accountId === ''"
    >
      <span class="text-container">Account Grouping</span>
    </button>
  </div>

  @if (accountId === '') {
    <app-account-grouping-grid></app-account-grouping-grid>
  } @else {
    <app-account-grouping-detail
      [accountId]="accountId"
    ></app-account-grouping-detail>
  }
</div>
