import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CaseManagementService } from '../../../case-management/case-management.service';
import { PostTradeService } from '../../post-trade.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Platform } from '@angular/cdk/platform';
import { saveAs } from '@progress/kendo-file-saver';
import { EnvironmentService } from '../../../services/environment.service';
import { uniqBy } from 'lodash';
import { Location } from '@angular/common';

@Component({
  selector: 'app-trade-attachments-dialog',
  templateUrl: './trade-attachments-dialog.component.html',
  styleUrls: ['./trade-attachments-dialog.component.scss'],
})
export class TradeAttachmentsDialogComponent implements OnInit {
  baseUrl: string;
  environment;
  note = '';
  fileName;
  fileNameDisplay;
  file;
  fileType;
  caseFileCols = [
    {
      varName: 'CreateDate',
      label: 'Date',
    },
    {
      varName: 'NoteText',
      label: 'Note',
    },
    {
      varName: 'CreatedByUserId',
      label: 'Reviewer ID',
    },
    {
      varName: 'Name',
      label: 'Attachments',
    },
  ];
  relatedCases = [];
  tradeAttachments = [];
  caseType;
  caseTitle;
  relatedCaseTypes;

  constructor(
    public cms: CaseManagementService,
    public ptSvc: PostTradeService,
    public dialogRef: MatDialogRef<TradeAttachmentsDialogComponent>,
    private snacky: MatSnackBar,
    private pltfrm: Platform,
    private envSvc: EnvironmentService,
    private location: Location,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    if (this.data.bulk) {
      if (this.data.profileType === 'trade') {
        if (this.data.bulkAction === 'initiateCase') {
          this.caseType = 'TradeGroup';
        } else {
          this.caseType = 'TradeBulk';
        }
        this.caseTitle = this.data.tradeId;
      } else if (this.data.profileType === 'account') {
        if (this.data.bulkAction === 'initiateCase') {
          this.caseType = 'AccountGroup';
        } else {
          this.caseType = 'AccountBulk';
        }
        this.caseTitle = this.data.AccountID;
      } else if (this.data.profileType === 'rep') {
        if (this.data.bulkAction === 'initiateCase') {
          this.caseType = 'RepGroup';
        } else {
          this.caseType = 'RepBulk';
        }
        this.caseTitle = this.data.RepID;
      }
    } else {
      if (this.data.profileType === 'trade') {
        if (
          this.data.bulkAction === 'initiateCase' ||
          this.data.bulkAction === undefined
        ) {
          this.caseType = 'TradeInternal';
        } else {
          this.caseType = 'Trade';
        }
        this.relatedCaseTypes = ['TradeInternal', 'TradeBulk'];
        this.caseTitle = this.data.tradeId;
      } else if (this.data.profileType === 'account') {
        if (
          this.data.bulkAction === 'initiateCase' ||
          this.data.bulkAction === undefined
        ) {
          this.caseType = 'AccountInternal';
        } else {
          this.caseType = 'Account';
        }
        this.relatedCaseTypes = ['AccountInternal', 'AccountBulk'];
        this.caseTitle = this.data.AccountID;
      } else if (this.data.profileType === 'rep') {
        if (
          this.data.bulkAction === 'initiateCase' ||
          this.data.bulkAction === undefined
        ) {
          this.caseType = 'RepInternal';
        } else {
          this.caseType = 'Rep';
        }
        this.relatedCaseTypes = ['RepInternal', 'RepBulk'];
        this.caseTitle = this.data.RepID;
      }
    }

    if (!this.data.bulk && this.data.tradeId) {
      this.cms
        .getRelatedCases(this.data.tradeId, this.relatedCaseTypes)
        .subscribe(resp => {
          if (resp['length'] > 0) {
            const cases = Object.keys(resp);
            const caseIds = [];

            cases.map(idx => {
              this.relatedCases.push(resp[idx]);
              caseIds.push(resp[idx].CRID);
            });

            this.cms
              .getCaseNotesAndFiles(JSON.stringify(caseIds))
              .subscribe(filesResp => {
                if (filesResp['length'] > 0) {
                  const formattedAttachments = [];
                  for (const index in filesResp) {
                    const relatedFile = formattedAttachments.find(
                      formattedAttachment =>
                        formattedAttachment.CreateDate ===
                        filesResp[index]['CreateDate']
                    );
                    if (!relatedFile) {
                      formattedAttachments.push(filesResp[index]);
                    } else {
                      const mergedFile = Object.assign(
                        relatedFile,
                        filesResp[index]
                      );
                      formattedAttachments.push(mergedFile);
                    }
                  }

                  this.tradeAttachments = uniqBy(
                    formattedAttachments,
                    'CreateDate'
                  );
                }
              });
          }
        });
    }
  }

  onFileSelected(event) {
    this.file = event.target.files[0];

    if (this.file) {
      this.fileName = this.file.name;
    }
  }

  clearFile() {
    this.fileName = null;
    this.fileNameDisplay = null;
    this.file = null;
    this.fileType = null;
  }

  save() {
    if (this.data.bulk) {
      this.executeBulkAction();
    } else {
      const caseData = {
        CaseManagement: {
          Type: this.caseType,
          Title: this.data.tradeId,
        },
        CaseToProfile: {
          ProfileID: [this.data.tradeId],
        },
        Client_Rep: {
          RepCode: [this.data.tradeDetail.RepCode],
        },
      };

      if (this.relatedCases.length === 0) {
        this.cms
          .createCaseManagementRecord(null, caseData)
          .subscribe(createCaseResp => {
            if (createCaseResp['result']) {
              this.saveNotesAndAttachments(createCaseResp['result']);
            }
          });
      } else {
        this.relatedCases.map(relatedCase => {
          this.saveNotesAndAttachments(relatedCase.CRID);
        });
      }
    }
  }

  executeBulkAction() {
    const hasAttachment = this.tradeAttachments.length > 0;
    const configurationObj = {
      CaseType: this.caseType,
      CaseTitle: this.caseTitle,
      CreateCase: true,
    };
    let idKey = 'TradeID',
      statusKey = 'ApprovalStatus',
      approvalVal = 'APP';

    if (this.data.profileType === 'account') {
      idKey = 'AccountID';
      statusKey = 'ReviewStatus';
      approvalVal = 'REV';
    }

    if (this.data.profileType === 'rep') {
      idKey = 'RepID';
      statusKey = 'ReviewStatus';
      approvalVal = 'REV';
    }

    if (this.data.bulkAction === 'reopen') {
      approvalVal = 'REO';
      configurationObj['CreateCase'] = false;
      delete configurationObj['CaseType'];
    }

    if (!this.data.bulk && hasAttachment) {
      delete configurationObj['CaseType'];
    }

    if (this.data.bulkAction !== 'initiateCase') {
      configurationObj[statusKey] = approvalVal;
    }

    if (this.data.queryData) {
      configurationObj['UpdateQuery'] = this.data.queryData;
    } else {
      configurationObj[idKey] = this.data.bulkActionIds;
    }

    if (this.data.profileType === 'trade') {
      this.updateTrade(configurationObj);
    } else if (this.data.profileType === 'account') {
      this.updateAccount(configurationObj);
    } else if (this.data.profileType === 'rep') {
      this.updateRep(configurationObj);
    }
  }

  updateTrade(configData) {
    this.ptSvc.updateTrade(configData).subscribe(resp => {
      if (resp['crid']) {
        this.saveNotesAndAttachments(resp['crid']);
        if (this.data.bulkAction === 'initiateCase') {
          window.open(
            `${this.location.prepareExternalUrl('/case-management/')}${
              resp['crid']
            }`,
            '_blank'
          );
        }
      }
      if (this.data.bulkAction === 'reopen') {
        this.dialogRef.close();
        this.dialogRef = null;
      }
    });
  }

  updateAccount(configData) {
    this.ptSvc.updateAccount(configData).subscribe(resp => {
      if (resp['crid']) {
        this.saveNotesAndAttachments(resp['crid']);
        if (this.data.bulkAction === 'initiateCase') {
          window.open(
            `${this.location.prepareExternalUrl('/case-management/')}${
              resp['crid']
            }`,
            '_blank'
          );
        }
      }
      if (this.data.bulkAction === 'reopen') {
        this.dialogRef.close();
        this.dialogRef = null;
      }
    });
  }

  updateRep(configData) {
    this.ptSvc.updateRep(configData).subscribe(resp => {
      if (resp['crid']) {
        this.saveNotesAndAttachments(resp['crid']);
        if (this.data.bulkAction === 'initiateCase') {
          window.open(
            `${this.location.prepareExternalUrl('/case-management/')}${
              resp['crid']
            }`,
            '_blank'
          );
        }
      }
      if (this.data.bulkAction === 'reopen') {
        this.dialogRef.close();
        this.dialogRef = null;
      }
    });
  }

  saveNotesAndAttachments(caseId) {
    if (this.note !== '' && this.note !== null && !this.fileName) {
      const note = {
        CaseRecordId: caseId,
        NoteText: this.note,
        NoteTypeId: 2,
      };

      this.cms.addCaseRecordNote(note).subscribe(
        () => {
          this.note = '';
          this.openSnacky('Note saved');
          this.dialogRef.close();
          this.dialogRef = null;
        },
        error => {
          this.snacky.open(`Error: ${error}`);
        }
      );
    } else if (this.fileName && (this.note === '' || this.note === null)) {
      const formData = new FormData();
      formData.append('File1', this.file);
      this.fileName = '';

      this.cms.saveCaseRecordFile(caseId, formData).subscribe(
        () => {
          this.openSnacky('File uploaded.');
          this.dialogRef.close();
          this.dialogRef = null;
        },
        error => {
          this.snacky.open(`Error: ${error}`);
        }
      );
    } else {
      const formData = new FormData();
      formData.append('File1', this.file);
      this.fileName = '';
      this.cms.saveCaseRecordFile(caseId, formData).subscribe(
        resp => {
          if (resp['id']) {
            this.cms
              .addCaseRecordNote({
                CaseRecordId: caseId,
                CaseRecordFileId: resp['id'],
                NoteText: this.note,
                NoteTypeId: 2,
              })
              .subscribe(
                () => {
                  this.note = '';
                  this.openSnacky('File uploaded & note saved.');
                  this.dialogRef.close();
                  this.dialogRef = null;
                },
                error => {
                  this.snacky.open(`Error: ${error}`);
                }
              );
          }
        },
        error => {
          this.snacky.open(`Error: ${error}`);
        }
      );
    }
  }

  openSnacky(message) {
    this.snacky.open(message, 'Close', {
      duration: 3000,
    });
  }

  downloadAttachment(fileDetails) {
    this.cms.getCaseRecordFile(fileDetails.id).subscribe(resp => {
      const filename = `${fileDetails.Name}`;
      const iosSafari =
        this.pltfrm.IOS ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
      let fileData;
      const reader = new FileReader();
      reader.readAsDataURL(resp);
      reader.onloadend = () => {
        fileData = reader.result;
        saveAs(fileData, filename, {
          proxyURL: `${this.baseUrl}/util/proxy`,
          proxyTarget: '_self',
          forceProxy: iosSafari,
        });
      };
    });
  }

  cancel() {
    this.dialogRef.close('cancel');
    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = null;
    });
  }
}
