<div mat-dialog-header>
  <h1>Account Grouping <span>- Recommended Accounts</span></h1>
</div>
<div mat-dialog-content class="dialog-container">
  @if (!loading) {
    @if (tableView) {
      <table class="details col-12">
        <thead>
          <tr>
            <th></th>
            <th>Account ID</th>
          </tr>
        </thead>
        <tbody>
          @for (account of recommendedAccounts; track account.ID) {
            <tr>
              <td width="75">
                <div class="action-button-container">
                  <mat-checkbox
                    matTooltip="Select Account"
                    [(ngModel)]="selectedAccounts[account.ID]"
                    [ngModelOptions]="{ standalone: true }"
                  >
                  </mat-checkbox>
                </div>
              </td>
              <td>{{ account.ID }}</td>
            </tr>
          }
        </tbody>
      </table>
    } @else {
      <div>FORM VIEW</div>
    }
  }
</div>
<div mat-dialog-actions class="row buttons">
  <span>
    @if (tableView) {
      <button mat-stroked-button (click)="toggleView()">
        Refine Results
      </button>
    } @else {
      <button mat-raised-button color="accent" (click)="toggleView()">
        View Results
      </button>
    }
  </span>
  <span>
    <button mat-button color="warn" (click)="dialogRef.close('Cancel')">
      Cancel
    </button>
    @if (tableView) {
      <button
        mat-raised-button
        [disabled]="accountsNotSelected()"
        color="primary"
        (click)="linkAccounts()"
      >
        <mat-icon>save</mat-icon> Link Accounts
      </button>
    }
  </span>
</div>
<ngx-loading
  [show]="loading"
  [config]="{ fullScreenBackdrop: false }"
></ngx-loading>
