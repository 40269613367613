<div class="page-container product-shelf-container container">
  <app-utility-toolbar></app-utility-toolbar>
  <h1 class="header col-12">Plan Lookup</h1>
  <div class="row mb-3 mx-1 plan-lookup-filters" *ngIf="!hideGrid">
    <div class="col-12 mb-3 row toolbar">
      <form class="search-form w-100" (submit)="searchResults()">
        <div class="w-40 filters-container">
          <mat-form-field class="ml-2">
            <mat-label>Plan Type</mat-label>
            <mat-select [formControl]="planType">
              <mat-option *ngFor="let plan of planTypes" [value]="plan.value">
                {{ plan.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="ml-2">
            <mat-label>Plan/Sponsor Name</mat-label>
            <input matInput [formControl]="planName" />
          </mat-form-field>
          <mat-form-field class="ml-2">
            <mat-label>EIN</mat-label>
            <input matInput [formControl]="ein" />
          </mat-form-field>
          <mat-form-field class="ml-2">
            <mat-label>ZIP Code</mat-label>
            <input matInput [formControl]="zipCode" />
          </mat-form-field>
          <mat-form-field class="ml-2">
            <mat-label>Distance from ZIP (miles)</mat-label>
            <input
              matInput
              [formControl]="distance"
              [disabled]="!zipCode.value"
            />
          </mat-form-field>
          <button
            mat-flat-button
            color="primary"
            class="ml-2 update-search-button"
            type="submit"
            [disabled]="
              !planType.value && !planName.value && !ein.value && !zipCode.value
            "
          >
            Update Search
          </button>
        </div>
      </form>
    </div>
    <button
      *ngIf="gridData.headers?.length > 1"
      mat-button
      color="primary"
      class="grid-column-button"
      [matMenuTriggerFor]="columnMenu"
    >
      <mat-icon>view_column</mat-icon>Column Options
    </button>
    <mat-menu #columnMenu="matMenu" class="column-menu">
      <ng-container *ngFor="let col of gridData.headers">
        <button
          (click)="toggleVisible($event, col.DataField)"
          mat-menu-item
          [value]="col.DataField"
          [ngClass]="{ selected: !col.Hidden }"
        >
          <mat-icon class="empty">check_box_outline_blank</mat-icon>
          <mat-icon class="checked">check_box</mat-icon>
          {{ col.Label }}
        </button>
      </ng-container>
    </mat-menu>
  </div>

  <kendo-grid
    class="col-12"
    #productGrid="kendoGrid"
    [kendoGridBinding]="gridData.data"
    [skip]="state.skip"
    [pageSize]="state.take"
    [pageable]="true"
    [sortable]="{ allowUnsort: true, mode: 'multiple' }"
    [sort]="state.sort"
    [filterable]="'menu'"
    [filter]="state.filter"
    [state]="state"
    *ngIf="!hideGrid"
    [headerStyle]="{ 'white-space': 'pre-wrap' }"
    (filterChange)="updateFilters($event)"
    (dataStateChange)="dataStateChange($event)"
  >
    <kendo-grid-column title="Actions">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          mat-icon-button
          class="fs-button"
          (click)="dlFactsheet(dataItem)"
          color="primary"
          matTooltip="Download Factsheet"
        >
          <mat-icon>insert_drive_file</mat-icon>
        </button>
      </ng-template>
    </kendo-grid-column>
    <ng-template ngFor [ngForOf]="gridData.headers" let-column>
      <kendo-grid-column
        [field]="column.DataField"
        [hidden]="column.Hidden"
        [title]="column.Label"
        [headerStyle]="{ 'white-space': 'pre-wrap' }"
        [sortable]="column.Sort"
        [filterable]="column.Filter"
        [filter]="column.DataType"
      >
        <ng-template kendoGridColumnMenuTemplate let-service="service">
          <kendo-grid-columnmenu-sort [column]="column" [service]="service">
            <kendo-grid-messages
              [sortAscending]="getFilterMenuText(column.Type, 'asc')"
              [sortDescending]="getFilterMenuText(column.Type, 'desc')"
            ></kendo-grid-messages>
          </kendo-grid-columnmenu-sort>
        </ng-template>
        <ng-template
          kendoGridFilterMenuTemplate
          let-filter="filter"
          let-filterService="filterService"
        >
          <kendo-grid-sort-filter-menu
            [column]="column"
            [filter]="filter"
            [filterService]="filterService"
          >
            <kendo-grid-messages
              [sortAscending]="getFilterMenuText(column.Type, 'asc')"
              [sortDescending]="getFilterMenuText(column.Type, 'desc')"
            ></kendo-grid-messages>
          </kendo-grid-sort-filter-menu>
          <kendo-multiselect
            style="width: 99%"
            [data]="filterData[column.DataField]"
            textField="display"
            valueField="value"
            [valuePrimitive]="true"
            [value]="filter | filterValues"
            (valueChange)="
              filterValueChange($event, column.DataField, filterService)
            "
            [fillMode]="'outline'"
            placeholder="Filter By..."
          ></kendo-multiselect>
        </ng-template>
        <ng-template
          *ngIf="column.Type === 'bool'"
          kendoGridCellTemplate
          let-dataItem
        >
          @if (dataItem[column.DataField]) {
            Yes
          } @else if (
            !dataItem[column.DataField] && isBool(dataItem[column.DataField])
          ) {
            No
          }
        </ng-template>
        <ng-template
          *ngIf="column.Type === 'perc' || column.Type === 'perc3'"
          kendoGridCellTemplate
          let-dataItem
        >
          {{ dataItem[column.DataField] | percent: '1.1-3' }}
        </ng-template>
        <ng-template
          *ngIf="column.Type === 'curr'"
          kendoGridCellTemplate
          let-dataItem
        >
          {{ dataItem[column.DataField] | currency: 'USD' : true : '1.0-0' }}
        </ng-template>
        <ng-template
          *ngIf="column.Type === 'num'"
          kendoGridCellTemplate
          let-dataItem
        >
          {{ dataItem[column.DataField] | number }}
        </ng-template>
        <ng-template
          kendoGridCellTemplate
          let-dataItem
          *ngIf="column.DataField === 'notes'"
        >
          <span [innerHtml]="dataItem[column.DataField]"></span>
        </ng-template>
        <ng-template
          kendoGridCellTemplate
          let-dataItem
          *ngIf="column.DataField === 'PlanType'"
        >
          <span *ngIf="dataItem[column.DataField] === 'DC'"
            >Defined Contribution</span
          >
          <span *ngIf="dataItem[column.DataField] === 'DB'"
            >Defined Benefit</span
          >
        </ng-template>
        <ng-template
          kendoGridCellTemplate
          let-dataItem
          *ngIf="column.Validation"
        >
          {{ findValue(dataItem[column.DataField], column.Validation) }}
        </ng-template>
      </kendo-grid-column>
    </ng-template>
  </kendo-grid>
</div>
<ngx-loading [show]="loading"></ngx-loading>
