import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { InvestmentWizardService } from '../services/investment-wizard-service.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { FilterService } from '@progress/kendo-angular-grid';
import {
  SortDescriptor,
  State,
  GroupDescriptor,
  CompositeFilterDescriptor,
} from '@progress/kendo-data-query';
import { CustomFiltersService } from '../../services/custom-filters.service';
import { UserPreferencesService } from '../../services/user-preferences.service';
import { AccountsService } from '../../account-management/services/accounts-service.service';
import { CompareDialogComponent } from '../../compare-dialog/compare-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { IWProductShelfModalComponent } from './iw-product-shelf-modal/iw-product-shelf-modal.component';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { saveAs } from '@progress/kendo-file-saver';
import { getFilterMenuText } from '../../lib/grid-sort-text';

@Component({
  selector: 'app-product-grid',
  templateUrl: './product-grid.component.html',
  styleUrls: ['./product-grid.component.scss'],
})
export class ProductGridComponent implements OnInit, OnDestroy {
  @ViewChild('productGrid', { static: false }) productGrid;
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public group: GroupDescriptor[] = [];
  public hidden: string[] = [];
  public pageSize = 25;
  public pageSizeOptions = [25, 50, 75, 100];
  public skip = 0;
  public sort: SortDescriptor[] = [];
  public getFilterMenuText = getFilterMenuText;
  canExportGrid = false;
  compareChecked = {};
  compareList = [];
  dateFilters = [];
  dropdownFilters = [];
  externalFilters = [];
  filterData = {};
  filterOptions = {};
  gridColumns;
  gridView = 'product';
  hideGrid = false;
  intervalVar: Subscription;
  loading;
  ogData;
  ogExternalFilters = [];
  ogHeaders;
  ogHidden = [];
  org;
  paramsSubscription;
  programToFilter: string;
  range = { start: null, end: null };
  resetFilters = false;
  rights: string[];
  searchFilters = [];
  showNewProfile = false;
  unitSettings;
  gridData = {
    data: null,
    headers: null,
    subHead: new Map(),
    total: 0,
  };
  state: State = {
    filter: this.filter,
    sort: this.sort,
    skip: this.skip,
    take: this.pageSize,
  };
  tableDef = {
    id: 'compare1',
    name: 'compare1',
    label: 'table label',
    type: 'vert',
    header: {
      title: '',
      bullets: [],
    },
    footer: {
      footnotes: [],
    },
    columns: [
      {
        var: 'header1',
        label: 'Label',
      },
      {
        var: 'header1',
        label: 'Fund',
      },
    ],
    rows: [
      {
        var: 'Symbol',
        label: 'Symbol',
      },
      {
        var: 'Name',
        label: 'Name',
      },
      {
        var: 'FundType',
        label: 'Type',
      },
      {
        var: 'InceptionDate',
        label: 'Inception Date',
      },
      {
        var: 'FundFamilyName',
        label: 'Fund Family',
      },
      {
        var: 'RBCategory',
        label: 'Category',
      },
      {
        var: 'ShareClass',
        label: 'Share Class',
      },
      {
        var: 'ExpenseRatio',
        label: 'Expense Ratio',
      },
      {
        var: 'TrailingReturnYTD',
        label: 'Trailing YTD Return',
      },
      {
        var: 'TrailingReturnY1',
        label: 'Trailing 1 Year Return',
      },
      {
        var: 'TrailingReturnY3',
        label: 'Trailing 3 Year Return',
      },
      {
        var: 'TrailingReturnY5',
        label: 'Trailing 5 Year Return',
      },
      {
        var: 'TrailingReturnY10',
        label: 'Trailing 10 Year Return',
      },
      {
        var: '3yStandardDeviation',
        label: 'Standard Deviation',
      },
      {
        var: '3ySharpeRatio',
        label: 'Sharpe Ratio',
      },
      {
        var: 'RewardToSemiVarianceRatio',
        label: 'Reward to Risk Ratio',
      },
      {
        var: 'JensensAlpha',
        label: 'Alpha',
      },
      {
        var: 'Beta',
        label: 'Beta',
      },
      {
        var: 'ValueAtRisk',
        label: 'Value At Risk',
      },
      {
        var: 'R_Squared',
        label: 'R-Squared',
      },
      {
        var: 'TrackingErrorY3',
        label: '3 Yr Tracking Error',
      },
      {
        var: 'InformationRatio',
        label: 'Information Ratio',
      },
      {
        var: 'CaptureRatio',
        label: 'Capture Ratio',
      },
      {
        var: 'MgrTenure',
        label: 'Manager Tenure',
      },
      {
        var: 'MinimumInitialInvestment',
        label: 'Minimum Initial Investment',
      },
      {
        var: 'AverageCouponLong',
        label: 'Average Bond Maturity',
      },
      {
        var: 'AverageEffectiveDuration',
        label: 'Average Bond Duration',
      },
      {
        var: 'AverageCreditQuality',
        label: 'Average Credit Quality',
      },
      {
        var: 'FrontLoadSchedule',
        label: 'Breakpoint Schedule',
      },
    ],
  };

  constructor(
    private acctSrvc: AccountsService,
    private cstmFltrSrvc: CustomFiltersService,
    private iws: InvestmentWizardService,
    private location: Location,
    private params: ActivatedRoute,
    private prefSrvc: UserPreferencesService,
    private router: Router,
    private ss: SessionStorageService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.rights = this.ss.get('rights') || [];
    if (this.rights && this.rights.includes('HOProductShelfCSV')) {
      this.canExportGrid = true;
    }
    this.org = this.ss.get('globals') ? this.ss.get('globals').firmId : null;
    this.paramsSubscription = this.params.params.subscribe(params => {
      this.programToFilter = params.program;
      this.location.go(`iw/product-shelf`);
    });
    this.getGridSetup();
  }

  ngOnDestroy(): void {
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  getGridSetup() {
    this.hideGrid = true;
    this.loading = true;
    if (!this.programToFilter) {
      this.openProgramFilterModal();
    }
    this.intervalVar = this.iws.getProductGridSetup(this.org).subscribe(res => {
      this.ogHeaders = res.headers;
      this.gridData.headers = res.headers;
      this.filterOptions = res.dropdowns;
      this.gridColumns = res.headers.filter(x => !x.subgroup);
      this.updateExternalFilterOptions(this.gridData.headers);
      if (this.programToFilter) {
        this.getGridData();
        this.setColumnVisiblity();
      }
    });
  }

  gridHeight() {
    return window.innerHeight - 210;
  }

  openProgramFilterModal() {
    this.loading = true;
    const dialogRef = this.dialog.open(IWProductShelfModalComponent, {
      width: '50vw',
      disableClose: true,
      data: { currentProgram: this.programToFilter },
    });
    dialogRef.componentInstance.gridData = this.gridData;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'cancel') {
        this.programToFilter = result ? result : null;
        const intervalID = setInterval(() => {
          if (this.intervalVar.closed) {
            clearInterval(intervalID);
            this.getGridData();
            this.setColumnVisiblity();
          }
        }, 500);
      } else {
        this.loading = false;
      }
    });
  }

  public dataStateChange(state): void {
    this.loading = true;
    this.pageSize = state.take;
    this.state = state;
    this.filter = this.state.filter;
    this.sort = this.state.sort;
    this.getGridData();
  }

  getGridData(resType = 'application/json') {
    const queryData = {
      take: this.state.take,
      skip: this.state.skip,
      search: {},
      sort: {},
    };

    this.state.filter = this.filter;
    this.filterOptions['ProgramName'] = this.programToFilter;
    this.filter.filters.map(filter => {
      if (filter['filters']) {
        filter['filters'].map(nextFilter => {
          if (
            queryData.search[nextFilter.field] &&
            this.dropdownFilters.includes(nextFilter.field)
          ) {
            queryData.search[nextFilter.field].selected.push(nextFilter.value);
          } else if (
            !queryData.search[nextFilter.field] &&
            this.dropdownFilters.includes(nextFilter.field)
          ) {
            queryData.search[nextFilter['field']] = {
              selected: [nextFilter['value']],
            };
          }

          if (
            queryData.search[nextFilter.field] &&
            this.searchFilters.includes(nextFilter.field)
          ) {
            queryData.search[nextFilter.field].searchString = nextFilter.value;
          } else if (
            !queryData.search[nextFilter.field] &&
            this.searchFilters.includes(nextFilter.field)
          ) {
            queryData.search[nextFilter['field']] = {
              searchString: nextFilter['value'],
            };
          }

          if (
            queryData.search[nextFilter.field] &&
            this.dateFilters.includes(nextFilter.field)
          ) {
            queryData.search[nextFilter.field][nextFilter.operator] =
              DateTime.fromJSDate(new Date(nextFilter.value)).toUTC().toISO();
          } else if (
            !queryData.search[nextFilter.field] &&
            this.dateFilters.includes(nextFilter.field)
          ) {
            queryData.search[nextFilter['field']] = {};
            queryData.search[nextFilter['field']][nextFilter.operator] =
              DateTime.fromJSDate(new Date(nextFilter.value)).toUTC().toISO();
          }
        });
      } else {
        if (
          queryData.search[filter['field']] &&
          this.searchFilters.includes(filter['field'])
        ) {
          queryData.search[filter['field']].searchString = filter['value'];
        } else if (
          !queryData.search[filter['field']] &&
          this.searchFilters.includes(filter['field'])
        ) {
          queryData.search[filter['field']] = {
            searchString: filter['value'],
          };
        }

        if (queryData.search[filter['field']]) {
          queryData.search[filter['field']].selected.push(filter['value']);
        } else if (!queryData.search[filter['field']]) {
          queryData.search[filter['field']] = {
            selected: [filter['value']],
          };
        }
      }
    });

    this.sort.map(sortItem => {
      if (sortItem.dir !== undefined) {
        queryData.sort[sortItem.field] = sortItem.dir.toUpperCase();
      } else {
        delete queryData.sort[sortItem.field];
      }
    });

    if (!queryData.search['FirmID']) {
      queryData.search['FirmID'] = {
        selected: [this.org],
      };
    }
    if (this.programToFilter && !queryData.search['ProgramName']) {
      queryData.search['ProgramName'] = {
        searchString: this.programToFilter,
      };
    }

    if (Object.keys(queryData.sort).length <= 0) {
      queryData.sort = {
        ProgramName: 'ASC',
        FundID: 'ASC',
      };
    }

    if (resType === 'application/csv') {
      delete queryData.skip;
      delete queryData.take;
    }

    const reqData = { queryData: queryData, resType: resType };

    this.iws.getProductGrid(reqData).subscribe(res => {
      if (resType === 'application/csv') {
        const date = new Date();
        const programString = this.programToFilter.replaceAll(' ', '_');

        let fileData;
        const filename = `Product_Shelf-${
          queryData.search['FirmID']['selected']
        }-${programString}-${date.toLocaleString('en-US', {
          dateStyle: 'short',
        })}.csv`;
        const reader = new FileReader();
        reader.readAsDataURL(res);

        reader.onloadend = () => {
          fileData = reader.result;
          saveAs(fileData, filename);
        };
      } else {
        this.ogData = res.results.data;
        this.state.skip = res.results.skip;
        this.gridData.data = res.results.data;
        this.gridData.total = res.results.total;
      }

      setTimeout(() => {
        this.buildFilterOptions();
        this.hideGrid = false;
        this.loading = false;
      }, 1000);
    });
  }

  exportGrid(ev) {
    this.loading = true;
    this.getGridData('application/csv');
  }

  setColumnVisiblity() {
    this.checkHiddenCols();
    this.gridData.headers.forEach(x => {
      if (x.subgroup) {
        let tempGroup = [];
        if (this.gridData.subHead.has(x.subgroup)) {
          tempGroup = this.gridData.subHead.get(x.subgroup);
          tempGroup.push(x);
        } else {
          this.gridData.subHead.set(x.subgroup, []);
          tempGroup.push(x);
        }

        this.gridData.subHead.set(x.subgroup, tempGroup);
        this.gridData.headers = this.gridData.headers.filter(z => z != x);
      }

      switch (x.RespVis) {
        case 'xs':
          x.RespVis = '';
          break;
        case 'sm':
          x.RespVis = '(min-width: 700px)';
          break;
        case 'md':
          x.RespVis = '(min-width: 1100px)';
          break;
        case 'lg':
          x.RespVis = '(min-width: 1200px)';
          this.hideColumn(x.DataField);
          break;
        case 'xl':
          x.RespVis = '(min-width: 1500px)';
          this.hideColumn(x.DataField);
          break;
      }
      if (this.hidden.length === 0) {
        if (x.Hidden) {
          this.hidden.push(x.DataField);
          this.ogHidden.push(x.DataField);
        }
      }

      x.Width = 150;
    });

    this.hidden.forEach(x => {
      this.hideColumn(x);
    });
  }

  checkHiddenCols(): void {
    this.hidden = this.ss.get('hiddenIWProductColumns')
      ? this.ss.get('hiddenIWProductColumns')
      : [];
  }

  updateHidden(ev) {
    this.hidden = ev.value;
    this.setStorage();
  }

  saveUserDefinedColumns() {
    const fullList = this.gridColumns.map(x => {
      return x.DataField;
    });
    const visible = fullList.filter(x => !this.hidden.includes(x));

    this.prefSrvc.saveColumns({ iwShelf: visible }).subscribe();
  }

  externalColumnsUpdated(ev) {
    const fullList = this.gridColumns.map(x => {
      return x.DataField;
    });
    const hidden = fullList.filter(y => !ev.includes(y));

    this.updateHidden({ value: hidden });
    this.saveUserDefinedColumns();
  }

  buildDropdownFilterOptions(el) {
    let options;
    options = this.filterOptions[el.DataField].map(x => {
      return { value: x, display: x };
    });
    options = this.deDupObjectArray(options);
    options = this.clearEmptyFields(options);
    options = options.sort((a, b) => (a.display > b.display ? 1 : -1));

    if (el.Validation) {
      options = options.map(x => {
        let label = el.Validation.find(z => x.value == z.value);

        label = label ? label.label : x.value;

        return { value: x.value, display: label };
      });
    }

    return options;
  }

  buildFilterOptions() {
    this.filterData = this.cstmFltrSrvc.buildColumnFilterOptions(
      this.ogData,
      this.gridColumns
    );

    if (this.filterOptions) {
      for (const [key, value] of Object.entries(this.filterOptions)) {
        this.filterData[key] = value;
      }
    }
  }

  setEmptySubheads() {
    this.gridData.subHead.clear();
  }

  isHidden(columnName: string): boolean {
    return this.hidden.indexOf(columnName) > -1;
  }

  hideColumn(columnName: string): void {
    if (!this.isHidden(columnName)) {
      this.hidden.push(columnName);
      this.setStorage();
    }
  }

  setStorage() {
    this.ss.set('hiddenIWProductColumns', this.hidden);
  }

  reset() {
    this.sort = [];
    this.filter = { logic: 'and', filters: [] };
    this.group = [];
    this.compareList = [];
    this.compareChecked = {};
    this.skip = 0;
    this.buildFilterOptions();
    this.externalFilters = [];

    this.state = {
      filter: this.filter,
      sort: this.sort,
      skip: this.skip,
      take: this.pageSize,
    };

    this.resetFilters = true;

    this.getGridSetup();
  }

  compare() {
    const compareData = {
      funds: this.compareList,
      header: 'Compare Funds',
      tables: [this.tableDef],
      type: 'iwGrid',
      iwProductShelf: true,
    };

    const dialogRef = this.dialog.open(CompareDialogComponent, {
      panelClass: 'compare-dialog',
      height: '90vh',
      width: '90vw',
      maxHeight: '90vh',
      maxWidth: '90vw',
      data: compareData,
      autoFocus: false,
    });
    dialogRef.afterClosed();
  }

  compareListUpdate(ev, item) {
    ev.preventDefault();
    const indexExisting = this.compareList.findIndex(x => x === item);

    if (indexExisting > -1) {
      this.compareList = this.compareList.filter(x => {
        return x != item;
      });
      this.compareChecked[item.FundID] = false;
    } else {
      this.compareList.push(item);
      this.compareChecked[item.FundID] = true;
    }
  }

  findValue(val, list) {
    if (val || val === 0 || (!val && typeof val == 'boolean')) {
      const display = list.find(x => x.value == val)
        ? list.find(x => x.value == val).label
        : val;
      return display;
    }
    return null;
  }

  updateExternalFilterOptions(headers) {
    headers
      .filter(x => x.externalFilter)
      .forEach(el => {
        const entry = el.externalFilter;
        entry.varName = el.DataField;
        entry.fullField = el;
        this.externalFilters.push(entry);

        if (el.DataField === 'ProgramDisplayName') {
          this.acctSrvc.programSearch(true).subscribe(res => {
            const filterOptions = [];

            if (res.results.length > 0) {
              res.results.map(prog => {
                filterOptions.push({
                  value: prog.DisplayName,
                  display: prog.DisplayName,
                });
              });
            }
            entry.options = filterOptions;
          });
        }

        if (el.Type && el.Type.toLowerCase() == 'curr') {
          entry.controlType = 'currency';
        }

        const entryIdx = this.externalFilters.findIndex(x => {
          return x.varName == el.DataField;
        });
        this.externalFilters[entryIdx].options =
          this.buildDropdownFilterOptions(el);
      });
  }

  originalOrder = (): number => {
    return 0;
  };

  deDupObjectArray(data) {
    const uniqueArray = data.filter(
      (object, index) =>
        index ===
        data.findIndex(obj => JSON.stringify(obj) === JSON.stringify(object))
    );
    return uniqueArray;
  }

  clearEmptyFields(data) {
    const updated = data.filter(item => {
      if (typeof item.value === 'string' && item.value.length < 1) {
        return false;
      }

      return item;
    });

    return updated;
  }

  updateFilters(ev) {
    this.loading = true;
    this.resetFilters = false;
    this.skip = 0;
    this.filter = ev;

    this.state = {
      filter: this.filter,
      sort: this.sort,
      skip: this.skip,
      take: this.pageSize,
    };

    this.getGridData();
  }

  public multiselectFilterValueChange(
    values,
    field,
    filterService: FilterService
  ): void {
    if (!this.dropdownFilters.includes(field)) {
      this.dropdownFilters.push(field);
    }

    filterService.filter({
      filters: values.map(value => ({
        field: field,
        operator: 'contains',
        value,
      })),
      logic: 'or',
    });
  }

  public searchFilterValueChange(
    values,
    field,
    filterService: FilterService
  ): void {
    if (!this.searchFilters.includes(field)) {
      this.searchFilters.push(field);
    }

    filterService.filter({
      filters: [
        {
          field: field,
          operator: 'contains',
          value: values,
        },
      ],
      logic: 'or',
    });
  }

  public dateFilterValueChange(field): void {
    if (!this.dateFilters.includes(field)) {
      this.dateFilters.push(field);
    }
  }
}
