import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { PostTradeService } from '../../post-trade.service';
import {
  SortDescriptor,
  State,
  CompositeFilterDescriptor,
} from '@progress/kendo-data-query';
import { CustomFiltersService } from '../../../services/custom-filters.service';
import { Router } from '@angular/router';
import { FormControl, FormBuilder } from '@angular/forms';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { scoreClassKey } from '../trade-review-detail/trade-review-detail-keys';
import { MatDialog } from '@angular/material/dialog';
import { ReviewDownloadDialogComponent } from '../../review-download-dialog/review-download-dialog.component';
import { TradeAttachmentsDialogComponent } from '../trade-attachments-dialog/trade-attachments-dialog.component';
import { getFilterMenuText } from '../../../lib/grid-sort-text';

@Component({
  selector: 'app-trade-review-grid',
  templateUrl: './trade-review-grid.component.html',
  styleUrls: ['./trade-review-grid.component.scss'],
})
export class TradeReviewGridComponent implements OnInit, OnDestroy {
  @ViewChild('postTradeGrid', { static: false }) postTradeGrid;

  constructor(
    public fb: FormBuilder,
    private cstmFltrSrvc: CustomFiltersService,
    public ptSvc: PostTradeService,
    private route: Router,
    private dialog: MatDialog
  ) {}

  unsubscribe: Subject<any> = new Subject();
  loading = false;
  public hidden: string[] = [];
  public ogHidden: string[] = [];
  public pageSize = 25;
  public pageSizeOptions = [25, 50, 75, 100];
  public skip = 0;
  public sort: SortDescriptor[] = [];
  public filter: CompositeFilterDescriptor = { logic: 'and', filters: [] };
  public getFilterMenuText = getFilterMenuText;
  ogData;
  gridData = {
    data: null,
    headers: null,
    subHead: new Map(),
    total: 0,
  };
  state: State = {
    filter: this.filter,
    sort: this.sort,
    skip: this.skip,
    take: this.pageSize,
  };
  hideGrid = true;
  externalFilters = [];
  dropdownFilters = [];
  searchFilters = [];
  dateFilters = [];
  presetDateFilters = [];
  filterOptions = {};
  filterData = {};
  resetFilters = false;
  bulkActionOptions = [
    { display: 'Approve', value: 'approve' },
    { display: 'Initiate Case', value: 'initiateCase' },
    { display: 'Reopen', value: 'reopen' },
  ];
  selectedTradeList = [];
  selectedChecked = {};
  bulkSelectControl = false;
  bulkActionControl = new FormControl();
  scoreClassKey = scoreClassKey;

  ngOnInit(): void {
    this.getGridSetup();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getGridSetup() {
    this.hideGrid = true;
    this.loading = true;
    this.ptSvc.getPostGridSetup().subscribe(
      resp => {
        this.gridData.headers = resp['headers'];
        this.updateExternalFilterOptions(this.gridData.headers);
        setTimeout(() => {
          this.getGridData();
          this.setColumnVisiblity();
        }, 1000);
      },
      error => {
        console.error('GRID SETUP ERROR: ', error);
        this.loading = false;
      }
    );
  }

  getGridData() {
    this.ptSvc
      .getPostTradeGridData(JSON.stringify(this.generateQueryData()))
      .subscribe(
        resp => {
          this.gridData.data = resp['results']['data'];
          this.ogData = this.gridData.data;
          this.state.skip = resp['results']['skip'];
          this.state.take = this.pageSize;
          this.gridData.total = resp['results']['total'];
          setTimeout(() => {
            this.buildFilterOptions();
            this.hideGrid = false;
            this.loading = false;
          }, 1000);
        },
        error => {
          this.loading = false;
          console.error('DATA ERROR: ', error);
        }
      );
  }

  generateQueryData() {
    const queryData = {
      take: this.state.take,
      skip: this.state.skip,
      search: {},
      sort: {},
    };

    this.filter.filters.map(filter => {
      if (filter['filters']) {
        filter['filters'].map(nextFilter => {
          if (
            queryData.search[nextFilter.field] &&
            this.dropdownFilters.includes(nextFilter.field)
          ) {
            queryData.search[nextFilter.field].selected.push(nextFilter.value);
          } else if (
            !queryData.search[nextFilter.field] &&
            this.dropdownFilters.includes(nextFilter.field)
          ) {
            queryData.search[nextFilter['field']] = {
              selected: [nextFilter['value']],
            };
          }
          if (
            queryData.search[nextFilter.field] &&
            this.searchFilters.includes(nextFilter.field)
          ) {
            queryData.search[nextFilter.field].searchString = nextFilter.value;
          } else if (
            !queryData.search[nextFilter.field] &&
            this.searchFilters.includes(nextFilter.field)
          ) {
            queryData.search[nextFilter['field']] = {
              searchString: nextFilter['value'],
            };
          }
          if (
            queryData.search[nextFilter.field] &&
            this.dateFilters.includes(nextFilter.field)
          ) {
            queryData.search[nextFilter.field][nextFilter.operator] =
              DateTime.fromJSDate(new Date(nextFilter.value)).toUTC().toISO();
          } else if (
            !queryData.search[nextFilter.field] &&
            this.dateFilters.includes(nextFilter.field)
          ) {
            queryData.search[nextFilter['field']] = {};
            queryData.search[nextFilter['field']][nextFilter.operator] =
              DateTime.fromJSDate(new Date(nextFilter.value)).toUTC().toISO();
          }
          if (
            queryData.search[nextFilter.field] &&
            this.presetDateFilters.includes(nextFilter.field)
          ) {
            if (
              filter['value'] === 'mtd' ||
              filter['value'] === 'ytd' ||
              filter['value'] === '30 days' ||
              filter['value'] === '90 days' ||
              filter['value'] === 'custom'
            ) {
              this.cstmFltrSrvc.calculatePresetRange(nextFilter, queryData);
            } else {
              queryData.search[filter['field']]['start'] =
                this.filter.filters.find(
                  filterObj =>
                    filterObj['field'] === filter['field'] &&
                    filterObj['operator'] === 'start'
                )['value'];
            }
          } else if (
            !queryData.search[nextFilter.field] &&
            this.presetDateFilters.includes(nextFilter.field)
          ) {
            if (
              filter['value'] === 'mtd' ||
              filter['value'] === 'ytd' ||
              filter['value'] === '30 days' ||
              filter['value'] === '90 days' ||
              filter['value'] === 'custom'
            ) {
              this.cstmFltrSrvc.calculatePresetRange(nextFilter, queryData);
            } else {
              queryData.search[filter['field']]['end'] =
                this.filter.filters.find(
                  filterObj =>
                    filterObj['field'] === filter['field'] &&
                    filterObj['operator'] === 'end'
                )['value'];
            }
          }
        });
      } else {
        if (
          queryData.search[filter['field']] &&
          this.searchFilters.includes(filter['field'])
        ) {
          queryData.search[filter['field']].searchString = filter['value'];
        } else if (
          !queryData.search[filter['field']] &&
          this.searchFilters.includes(filter['field'])
        ) {
          queryData.search[filter['field']] = {
            searchString: filter['value'],
          };
        }

        if (
          !queryData.search[filter['field']] &&
          this.dateFilters.includes(filter['field'])
        ) {
          queryData.search[filter['field']] = {};
          queryData.search[filter['field']][filter['operator']] =
            filter['value'];
        } else if (
          queryData.search[filter['field']] &&
          this.dateFilters.includes(filter['field'])
        ) {
          queryData.search[filter['field']][filter['operator']] =
            filter['value'];
        }

        if (
          !queryData.search[filter['field']] &&
          this.presetDateFilters.includes(filter['field'])
        ) {
          queryData.search[filter['field']] = {};
          if (
            filter['value'] === 'mtd' ||
            filter['value'] === 'ytd' ||
            filter['value'] === '30 days' ||
            filter['value'] === '90 days' ||
            filter['value'] === 'custom'
          ) {
            this.cstmFltrSrvc.calculatePresetRange(filter, queryData);
          } else {
            queryData.search[filter['field']]['start'] =
              this.filter.filters.find(
                filterObj =>
                  filterObj['field'] === filter['field'] &&
                  filterObj['operator'] === 'start'
              )['value'];
            queryData.search[filter['field']]['end'] = this.filter.filters.find(
              filterObj =>
                filterObj['field'] === filter['field'] &&
                filterObj['operator'] === 'end'
            )['value'];
          }
        } else if (
          queryData.search[filter['field']] &&
          this.presetDateFilters.includes(filter['field'])
        ) {
          if (
            filter['value'] === 'mtd' ||
            filter['value'] === 'ytd' ||
            filter['value'] === '30 days' ||
            filter['value'] === '90 days' ||
            filter['value'] === 'custom'
          ) {
            this.cstmFltrSrvc.calculatePresetRange(filter, queryData);
          } else {
            queryData.search[filter['field']]['start'] =
              this.filter.filters.find(
                filterObj =>
                  filterObj['field'] === filter['field'] &&
                  filterObj['operator'] === 'start'
              )['value'];
            queryData.search[filter['field']]['end'] = this.filter.filters.find(
              filterObj =>
                filterObj['field'] === filter['field'] &&
                filterObj['operator'] === 'end'
            )['value'];
          }
        }
        // else if (
        //   queryData.search[filter['field']] &&
        //   queryData.search[filter['field']].selected
        // ) {
        // queryData.search[filter['field']].selected.push(filter['value']);
        // }
        else if (
          !queryData.search[filter['field']] &&
          typeof filter['value'] === 'object'
        ) {
          queryData.search[filter['field']] = {
            selected: filter['value'],
          };
        } else if (
          !queryData.search[filter['field']] &&
          typeof filter['value'] !== 'object'
        ) {
          queryData.search[filter['field']] = {
            selected: [filter['value']],
          };
        }
      }
    });

    this.sort.map(sortItem => {
      if (sortItem.dir !== undefined) {
        queryData.sort[sortItem.field] = sortItem.dir.toUpperCase();
      } else {
        delete queryData.sort[sortItem.field];
      }
    });

    if (isEmpty(queryData.sort)) {
      queryData.sort = {
        TradeID: 'ASC',
      };
    }

    if (isEmpty(queryData.search)) {
      delete queryData.search;
    }

    return queryData;
  }

  updateExternalFilterOptions(headers) {
    headers
      .filter(x => x.externalFilter)
      .forEach(el => {
        const entry = el.externalFilter;
        entry.varName = el.DataField;
        entry.fullField = el;
        this.externalFilters.push(entry);
        if (el.Type && el.Type.toLowerCase() == 'curr') {
          entry.controlType = 'currency';
        }

        if (el.FilterType === 'Dropdown') {
          this.dropdownFilters.push(entry.fullField.DataField);
        } else if (el.FilterType === 'Search') {
          this.searchFilters.push(entry.fullField.DataField);
        } else if (
          el.FilterType === 'Range' &&
          el.externalFilter.controlType === 'presetRange'
        ) {
          this.presetDateFilters.push(entry.fullField.DataField);
        } else if (el.FilterType === 'Range') {
          this.dateFilters.push(entry.fullField.DataField);
        }

        const entryIdx = this.externalFilters.findIndex(x => {
          return x.varName == el.DataField;
        });
        this.externalFilters[entryIdx].options =
          this.buildDropdownFilterOptions(el);
      });
  }

  buildDropdownFilterOptions(el) {
    let options;
    options = this.filterOptions[el.DataField]
      ? this.filterOptions[el.DataField].map(x => {
          return { value: x, display: x };
        })
      : [];
    options = this.deDupObjectArray(options);
    options = this.clearEmptyFields(options);
    options = options.sort((a, b) => (a.display > b.display ? 1 : -1));

    if (el.Validation) {
      options = el.Validation.map(x => {
        const label = x.label ? x.label : x.value;

        return { value: x.value, display: label };
      });
    } else if (!el.Validation && el.externalFilter.options) {
      options = el.externalFilter.options.map(x => {
        const label = x.display ? x.display : x.value;

        return { value: x.value, display: label };
      });
    }

    return options;
  }

  buildFilterOptions() {
    this.filterData = this.cstmFltrSrvc.buildColumnFilterOptions(
      this.ogData,
      this.gridData.headers
    );

    if (this.filterOptions) {
      for (const [key, value] of Object.entries(this.filterOptions)) {
        this.filterData[key] = value;
      }
    }
  }

  public dataStateChange(state): void {
    this.loading = true;
    this.pageSize = state.take;
    this.state = state;
    this.filter = this.state.filter;
    this.sort = this.state.sort;
    this.getGridData();
  }

  updateFilters(ev) {
    this.resetFilters = false;
    this.skip = 0;
    this.filter = ev;

    this.state = {
      filter: this.filter,
      sort: this.sort,
      skip: this.skip,
      take: this.pageSize,
    };
  }

  externalColumnsUpdated(ev) {
    // This is a hack to stop the grid from scrolling to the right
    const gridContentScroll = document.querySelector('.k-grid-content');
    const oldScrollPosition = gridContentScroll.scrollLeft;

    const headerWrapContentScroll = document.querySelector(
      '.k-grid-header-wrap'
    );
    const headerWrapPosition = headerWrapContentScroll.scrollLeft;
    setTimeout(() => {
      gridContentScroll.scrollLeft = oldScrollPosition;
      headerWrapContentScroll.scrollLeft = headerWrapPosition;
    });

    const fullList = this.gridData.headers.map(x => {
      return x.DataField;
    });
    const hidden = fullList.filter(y => !ev.includes(y));

    this.updateHidden({ value: hidden });
  }

  setColumnVisiblity() {
    this.hidden = [];
    this.gridData.headers.forEach(x => {
      if (x.subgroup) {
        let tempGroup = [];
        if (this.gridData.subHead.has(x.subgroup)) {
          tempGroup = this.gridData.subHead.get(x.subgroup);
          tempGroup.push(x);
        } else {
          this.gridData.subHead.set(x.subgroup, []);
          tempGroup.push(x);
        }

        this.gridData.subHead.set(x.subgroup, tempGroup);
        this.gridData.headers = this.gridData.headers.filter(z => z != x);
      }

      if (x.Hidden) {
        this.hidden.push(x.DataField);
        this.ogHidden.push(x.DataField);
      }
    });
  }

  updateHidden(ev) {
    this.hidden = [...ev.value];
  }

  isHidden(columnName: string): boolean {
    return this.hidden.indexOf(columnName) > -1;
  }

  clearEmptyFields(data) {
    const updated = data.filter(item => {
      if (typeof item.value === 'string' && item.value.length < 1) {
        return false;
      }

      return item;
    });

    return updated;
  }

  deDupObjectArray(data) {
    const uniqueArray = data.filter(
      (object, index) =>
        index ===
        data.findIndex(obj => JSON.stringify(obj) === JSON.stringify(object))
    );
    return uniqueArray;
  }

  reset() {
    this.sort = [];
    this.filter = { logic: 'and', filters: [] };
    this.skip = 0;
    // this.buildFilterOptions();
    this.externalFilters = [];

    this.state = {
      filter: this.filter,
      sort: this.sort,
      skip: this.skip,
      take: this.pageSize,
    };

    this.resetFilters = true;

    this.getGridSetup();
  }

  viewTrade(ev) {
    this.route.navigate([`/trade-review/${ev.TradeID}`]);
  }

  bulkSelect({ checked }) {
    this.selectedTradeList = [];
    this.selectedChecked = {};
    if (checked) {
      this.gridData.data.map(dataObj => this.toggleTradeSelect(dataObj));
    }
  }

  selectAllCheck() {
    if (this.selectedTradeList.length === this.gridData.data.length) {
      return true;
    }
    return false;
  }

  toggleTradeSelect(item) {
    const indexExisting = this.selectedTradeList.findIndex(x => x === item);
    if (indexExisting > -1) {
      this.selectedTradeList = this.selectedTradeList.filter(x => {
        return x != item;
      });
      this.selectedChecked[item.TradeID] = false;
    } else {
      this.selectedTradeList.push(item);
      this.selectedChecked[item.TradeID] = true;
    }
  }

  resetBulkAction(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    this.bulkActionControl.setValue('');
  }

  executeBulkAction() {
    // Build a list of IDs for bulk actions
    const bulkActionIds = [];
    this.selectedTradeList.map(trade => {
      bulkActionIds.push(trade.TradeID);
    });

    const dialogData = {
      bulk: true,
      bulkActionIds: bulkActionIds,
      bulkAction: this.bulkActionControl.value,
      profileType: 'trade',
    };

    if (this.selectAllCheck()) {
      dialogData['queryData'] = this.generateQueryData();
    }

    const bulkActionDialog = this.dialog.open(TradeAttachmentsDialogComponent, {
      panelClass: 'app-bulk-action-dialog',
      data: dialogData,
    });

    bulkActionDialog.afterClosed().subscribe(resp => {
      if (resp !== 'cancel') {
        this.getGridData();
      }
    });
  }

  displayValue(datafield, value) {
    const hasDisplayValue =
      datafield.Validation &&
      datafield.Validation.find(valOption => valOption.value === value);
    if (hasDisplayValue) {
      return datafield.Validation.find(valOption => valOption.value === value)
        .label;
    } else {
      return value;
    }
  }

  alertListDisplay(value) {
    if (value.includes(',')) {
      const newList = [];
      value.split(',').map(alertId => {
        newList.push(
          this.gridData.headers
            .find(header => header.DataField === 'AlertIDs')
            .Validation.find(alert => alert.value === alertId).label
        );
      });
      return newList.join(', ');
    } else if (value !== '') {
      return this.gridData.headers
        .find(header => header.DataField === 'AlertIDs')
        .Validation.find(alert => alert.value === value).label;
    }

    return value;
  }

  downloadGridReport() {
    const visibleColumns = [];

    this.gridData.headers.map(header => {
      if (!this.hidden.includes(header.DataField)) {
        visibleColumns.push(header.DataField);
      }
    });

    this.dialog.open(ReviewDownloadDialogComponent, {
      panelClass: 'trade-review-download',
      minHeight: '30vh',
      width: '50vw',
      data: {
        columns: visibleColumns,
        rows: this.gridData.total,
        query: this.generateQueryData(),
        gridType: 'trade',
      },
      autoFocus: false,
    });
  }
}
