import { Component, Inject, ViewEncapsulation, OnInit } from '@angular/core';
import { PostTradeService } from '../../post-trade.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-account-grouping-dialog',
  templateUrl: './account-grouping-dialog.component.html',
  styleUrls: ['./account-grouping-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AccountGroupingDialogComponent implements OnInit {
  recommendedAccounts = [{ ID: 1 }, { ID: 2 }];
  selectedAccounts = {};
  loading = false;
  tableView = true;

  constructor(
    public dialogRef: MatDialogRef<AccountGroupingDialogComponent>,
    private ptSvc: PostTradeService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.getSuggestedAccounts();
  }

  getSuggestedAccounts() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 2000);
    console.log('GET RESULTS');
  }

  linkAccounts() {
    console.log('Link Acounts', this.selectedAccounts);
  }

  accountsNotSelected() {
    return !Object.values(this.selectedAccounts).some(value => value);
  }

  toggleView() {
    if (!this.tableView) {
      this.getSuggestedAccounts();
    }
    this.tableView = !this.tableView;
  }
}
