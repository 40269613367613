import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SessionStorageService } from '../services/session-storage.service';
import { WebHttpUrlEncodingCodec } from './fixedEncoding';
import { map, catchError } from 'rxjs/operators';
import { EnvironmentService } from '../services/environment.service';
import { ErrorReportingService } from '../services/error-reporting.service';

@Injectable()
export class BeaconService {
  private environment;
  private globals = this.ss.get('globals');
  private sessionId: string = this.ss.get('session');
  private baseUrl: string;
  private beaconBaseUrl = ' https://api.an.annuitynexus.com/api/v1/';
  private firm: string;
  private app: string;
  private org: string;
  private beaconUrl =
    ' https://api.an.annuitynexus.com/saml/consume?idp_id=aa454b149337b9ce';
  private token: string;
  private key: any = 'MetallicSwamp';
  private tabulaRecta = {
    a: 'abcdefghijklmnopqrstuvwxyz',
    b: 'bcdefghijklmnopqrstuvwxyza',
    c: 'cdefghijklmnopqrstuvwxyzab',
    d: 'defghijklmnopqrstuvwxyzabc',
    e: 'efghijklmnopqrstuvwxyzabcd',
    f: 'fghijklmnopqrstuvwxyzabcde',
    g: 'ghijklmnopqrstuvwxyzabcdef',
    h: 'hijklmnopqrstuvwxyzabcdefg',
    i: 'ijklmnopqrstuvwxyzabcdefgh',
    j: 'jklmnopqrstuvwxyzabcdefghi',
    k: 'klmnopqrstuvwxyzabcdefghij',
    l: 'lmnopqrstuvwxyzabcdefghijk',
    m: 'mnopqrstuvwxyzabcdefghijkl',
    n: 'nopqrstuvwxyzabcdefghijklm',
    o: 'opqrstuvwxyzabcdefghijklmn',
    p: 'pqrstuvwxyzabcdefghijklmno',
    q: 'qrstuvwxyzabcdefghijklmnop',
    r: 'rstuvwxyzabcdefghijklmnopq',
    s: 'stuvwxyzabcdefghijklmnopqr',
    t: 'tuvwxyzabcdefghijklmnopqrs',
    u: 'uvwxyzabcdefghijklmnopqrst',
    v: 'vwxyzabcdefghijklmnopqrstu',
    w: 'wxyzabcdefghijklmnopqrstuv',
    x: 'xyzabcdefghijklmnopqrstuvw',
    y: 'yzabcdefghijklmnopqrstuvwx',
    z: 'zabcdefghijklmnopqrstuvwxy',
  };
  private annuitySelectedSource = new Subject();

  annuitySelected$ = this.annuitySelectedSource.asObservable();

  constructor(
    private http: HttpClient,
    private ss: SessionStorageService,
    private envSvc: EnvironmentService,
    private errSvc: ErrorReportingService
  ) {
    this.environment = this.envSvc.get();
    this.baseUrl = this.environment.apiBase;
    this.org = this.environment.org;
    this.firm = this.environment.firm || '';
    this.app = this.ss.get('currentApp') || this.environment.defaultApp || 'pp';
    this.org = this.environment.org || 'basev2';
  }

  authenticate(): Observable<any> {
    const sess = this.ss.get('session'),
      globals = this.ss.get('globals'),
      userId = globals ? globals.user.id : null,
      entity = 'https://dev.rightbridge.net/app',
      recipient =
        'https://api.an.annuitynexus.com/saml/consume?idp_id=aa454b149337b9ce',
      audience =
        'https://api.an.annuitynexus.com/saml/metadata?idp_id=aa454b149337b9ce';

    const encUser = this.encodeEmail(userId);

    const reqHeaders = new HttpHeaders()
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org)
      .set('X-RB-U', encUser + '@rightbridge.net')
      .set('X-RB-S', sess);

    const url =
      this.baseUrl +
      '/Session/createsamlresponse?entityId=' +
      entity +
      '&recipient=' +
      recipient +
      '&audience=' +
      audience;

    return this.http
      .post(url, {}, { headers: reqHeaders, responseType: 'text' })
      .pipe(
        map(res => {
          return res;
        }),
        catchError((error: any) => {
          return this.errSvc.handleError(error);
        })
      );
  }

  getToken(saml) {
    const params = new HttpParams({
      encoder: new WebHttpUrlEncodingCodec(),
    }).set('SAMLResponse', saml);
    const reqHeaders = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/xml',
      'Response-Type': 'text',
    });

    return this.http.post(this.beaconUrl, params, { headers: reqHeaders }).pipe(
      map(res => {
        this.token = res['token'];
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getCompanies(token) {
    const reqHeaders = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Response-Type', 'application/json')
      .set('token', this.token);

    const url = this.beaconBaseUrl + 'companies';
    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  async processProducts(data) {
    const products = await this.processBeaconData(data);
    return products;
  }

  processBeaconData(products) {
    const data = products;
    data.forEach(x => {
      const introDate = x.introduction_date
        ? Date.parse(x.introduction_date)
        : null;
      const closedDate = x.closed_date ? Date.parse(x.closed_date) : null;
      const prospDate = x.prospectus_date
        ? Date.parse(x.prospectus_date)
        : null;
      const suppDate = x.supplement_date ? Date.parse(x.supplement_date) : null;
      x.introduction_date = introDate;
      x.closed_date = closedDate;
      x.prospectus_date = prospDate;
      x.supplement_date = suppDate;

      x.Contract = x.product_name;

      // compare_date used for variable products
      if (suppDate) {
        x.compare_date = x.supplement_date;
        return;
      } else if (prospDate) {
        x.compare_date = x.prospectus_date;
        return;
      } else {
        x.compare_date = x.introduction_date;
      }
    });

    return data;
  }

  filterOtherProducts(products, date) {
    const purch_date = Date.parse(date);
    const output = [];

    products.forEach(x => {
      if (!x.closed_date || purch_date < x.closed_date) {
        if (
          !x.introduction_date &&
          x.closed_date &&
          purch_date < x.closed_date
        ) {
          output.push(x);
        }
        if (
          x.introduction_date &&
          x.closed_date &&
          purch_date >= x.introduction_date &&
          purch_date < x.closed_date
        ) {
          output.push(x);
        }
        if (
          x.introduction_date &&
          !x.closed_date &&
          purch_date >= x.introduction_date
        ) {
          output.push(x);
        }
        if (!x.introduction_date && !x.closed_date) {
          output.push(x);
        }
      }
    });

    return output;
  }

  filterVariableProducts(products, date) {
    const purch_date = Date.parse(date);
    const names = [];
    let earliestDate = 0;
    let closedDate = 0;
    const output = [];

    products.forEach(x => {
      let theProduct = {};

      if (!names.includes(x.product_name)) {
        const arr = products.filter(
          prdt => prdt.product_name == x.product_name
        );
        // if only one product add logic to only show if purch_date is before closed date if closed date exists
        if (arr.length == 1) {
          earliestDate = arr[0].introduction_date
            ? arr[0].introduction_date
            : arr[0].prospectus_date
              ? arr[0].prospectus_date
              : arr[0].compare_date;
          closedDate = arr[0].closed_date ? arr[0].closed_date : 4077494400000;
          if (purch_date >= earliestDate) {
            if (closedDate && purch_date <= closedDate) {
              output.push(x);
            }
          }
          names.push(x.product_name);
        } else {
          arr.forEach((y, i) => {
            closedDate = y.closed_date ? y.closed_date : 4077494400000;
            let prevCloseDateNotNull;

            if (i === 0) {
              prevCloseDateNotNull = !y.closed_date;
              earliestDate = y.introduction_date
                ? y.introduction_date
                : y.prospectus_date
                  ? y.prospectus_date
                  : y.compare_date;
              if (purch_date > earliestDate) {
                if (closedDate && purch_date <= closedDate) {
                  theProduct = y;
                }
              }
            }

            if (purch_date >= y.compare_date && purch_date > earliestDate) {
              if (closedDate && purch_date <= closedDate) {
                theProduct = y;
              } else if (
                !prevCloseDateNotNull &&
                y.closed_date &&
                purch_date >= y.closed_date
              ) {
                theProduct = y;
              }
            }
            prevCloseDateNotNull = !y.closed_date;
          });
          if (Object.keys(theProduct).length !== 0) {
            output.push(theProduct);
            theProduct = {};
          }
        }
        names.push(x.product_name);
      }
    });

    return output;
  }

  getProductData(company) {
    const reqHeaders = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Response-Type', 'application/json')
      .set('token', this.token);
    const url = this.beaconBaseUrl + 'products?company_id=' + company;
    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getProductDetails(key, type, token = this.token) {
    const reqHeaders = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Response-Type', 'application/json')
      .set('token', token);

    const url =
      this.beaconBaseUrl +
      'products/product_details?product_key=' +
      key +
      '&product_type=' +
      type;
    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getRiders(product) {
    const reqHeaders = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Response-Type', 'application/json')
      .set('token', this.token);

    const url =
      this.beaconBaseUrl +
      'products/va_riders?product_key=' +
      product.product_key +
      '&product_type=' +
      product.product_type;
    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  getRiderDetails(key, type, rider, rider_key?) {
    const reqHeaders = new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Response-Type', 'application/json')
      .set('token', this.token);

    let url = `${this.beaconBaseUrl}products/rider_details?product_key=${key}&product_type=${type}&rider_type=${rider}`;
    url = rider_key ? `${url}&rider_key=${rider_key}` : url;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  rbBeaconCounter(profile, prodId, prodName, carrierName) {
    const sess = this.ss.get('session'),
      userId = this.globals ? this.globals.user.id : null;

    const reqHeaders = new HttpHeaders()
      .set('X-RB-S', sess)
      .set('X-RB-I', this.app)
      .set('X-RB-O', this.org);

    const url =
      this.baseUrl +
      '/Beacon/TrackRequest/' +
      profile +
      '?productId=' +
      prodId +
      '&productName=' +
      prodName +
      '&carrierName=' +
      carrierName;

    return this.http.get(url, { headers: reqHeaders }).pipe(
      map(res => {
        return res;
      }),
      catchError((error: any) => {
        return this.errSvc.handleError(error);
      })
    );
  }

  encodeEmail(value, isDecrypt?) {
    if (isDecrypt) {
      for (let i = 0; i < this.key.length; i++)
        this.key[i] = (26 - this.key[i]) % 26;
    }
    return this.encrypt(value, this.key);
  }

  encrypt(plainText, keyword) {
    if (typeof plainText !== 'string') {
      return 'invalid plainText. Must be string, not ' + typeof plainText;
    }
    if (typeof keyword !== 'string') {
      return 'invalid keyword. Must be string, not ' + typeof keyword;
    }

    plainText = plainText.toLowerCase();
    keyword = keyword.match(/[a-z]/gi).join('').toLowerCase();
    let encryptedText = '';
    let specialCharacterCount = 0;

    for (let i = 0; i < plainText.length; i++) {
      const keyLetter = (i - specialCharacterCount) % keyword.length;
      const keywordIndex = this.tabulaRecta.a.indexOf(keyword[keyLetter]);

      if (this.tabulaRecta[plainText[i]]) {
        encryptedText += this.tabulaRecta[plainText[i]][keywordIndex];
      } else {
        encryptedText += plainText[i];
        specialCharacterCount++;
      }
    }

    return encryptedText;
  }

  decrypt(encryptedText, keyword) {
    if (typeof encryptedText !== 'string') {
      return (
        'invalid encryptedText. Must be string, not ' + typeof encryptedText
      );
    }
    if (typeof keyword !== 'string') {
      return 'invalid keyword. Must be string, not ' + typeof keyword;
    }

    encryptedText = encryptedText.toLowerCase();
    keyword = keyword.match(/[a-z]/gi).join('').toLowerCase();
    let decryptedText = '';
    let specialCharacterCount = 0;

    for (let i = 0; i < encryptedText.length; i++) {
      const keyLetter = (i - specialCharacterCount) % keyword.length;
      const keyRow = this.tabulaRecta[keyword[keyLetter]];

      if (keyRow.indexOf(encryptedText[i]) !== -1) {
        decryptedText += this.tabulaRecta.a[keyRow.indexOf(encryptedText[i])];
      } else {
        decryptedText += encryptedText[i];
        specialCharacterCount++;
      }
    }

    return decryptedText;
  }

  annuityData(ann) {
    this.annuitySelectedSource.next(ann);
  }
}
