<div class="page-container fa-container container">
  <p class="col-12 filter-label" *ngIf="cusip && cusipTitle">
    Currently filtering results to
    {{ cusipTitle.AnnuityProduct_AnnuityID }} &mdash;
    {{ cusipTitle.AnnuityProduct_Carrier }}
    {{ cusipTitle.AnnuityProduct_Contract }}
  </p>
  <!-- <pre>{{cusipTitle | json}}</pre> -->
  <!-- <div class="row col-11 preset-container">
    <h2 class="w-100">Preset Table Grouping and Filtering</h2>
    <button mat-raised-button (click)="useDefault('highCapRate_sp500')">Highest Cap Rate for S&amp;P 500 Index</button>
    <button mat-raised-button (click)="useDefault('spread_partic_sp500')">Spread with Participation Rate S&amp;P 500 Index</button>
    <button mat-raised-button (click)="useDefault('highCapRate_NonSp500')">Highest Cap Non S&amp;P 500 Indexes</button>
    <button mat-raised-button (click)="useDefault('spread_partic_NonSp500')">Spread with Participation Rate Non S&amp;P 500 Indexes</button>
    <button mat-raised-button (click)="useDefault('monthlyCalc_index')">Monthly Calculation Periods by Index</button>
    <button mat-raised-button (click)="useDefault('annualCalc_index')">Annual Calculation Periods by Index</button>
    <button mat-raised-button (click)="useDefault('multiCalc_index')">Multi-Year Calculation Periods by Index</button>
    <button mat-raised-button (click)="useDefault('highFixedRate')">Highest Fixed Rate</button>
    <button mat-raised-button color="primary" class="pull-right" (click)="useDefault('clear')">Clear Preset</button>
  </div> -->
  <div class="row w-100 controls">
    <form [formGroup]="form" class="col-4">
      <mat-form-field class="col-12 default-select">
        <mat-select
          [placeholder]="'Preset Table Filtering and Sorting'"
          formControlName="defaultsControl"
          (selectionChange)="useDefault($event)"
        >
          <mat-option *ngFor="let opt of defaults" [value]="opt.label">{{
            opt.label
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <app-grid-column-filter
      [columns]="gridColumns"
      (columnsUpdated)="externalColumnsUpdated($event)"
    ></app-grid-column-filter>

    <button
      mat-button
      color="primary"
      class="reset-button grid-button"
      (click)="useDefault({ value: 'clear' }, true)"
    >
      <mat-icon>replay</mat-icon>Reset
    </button>
    <button
      mat-button
      color="primary"
      class="grid-button"
      (click)="exportToExcel(faGrid)"
    >
      <mat-icon>cloud_download</mat-icon>Download Excel File
    </button>
    <!-- <mat-form-field class="col-4 hide-select">
      <mat-select [placeholder]="'Hide Columns'" [(value)]="hidden" (selectionChange)="updateHidden($event)" multiple>
        <mat-option *ngFor="let col of gridColumns" [value]="col.DataField">{{col.Label}}</mat-option>
      </mat-select>
    </mat-form-field> -->
  </div>
  <kendo-grid
    class="col-12"
    #faGrid="kendoGrid"
    [kendoGridBinding]="ogData"
    [pageSize]="pageSize"
    [pageable]="true"
    [resizable]="true"
    [columnMenu]="{ columnChooser: false }"
    [sortable]="{ allowUnsort: true, mode: 'multiple' }"
    [sort]="sort"
    [filter]="filter"
    filterable="menu"
    (filterChange)="filterChange($event)"
    [groupable]="true"
    [group]="group"
  >
    <ng-template ngFor [ngForOf]="gridColumns" let-column>
      <kendo-grid-column
        [field]="column.DataField"
        [hidden]="isHidden(column.DataField)"
        [title]="column.Label"
        [sortable]="column.Sort"
        [groupable]="column.Group"
        [media]="column.RespVis"
        [width]="column.Width"
      >
        <ng-template kendoGridColumnMenuTemplate let-service="service">
          <kendo-grid-columnmenu-sort [column]="column" [service]="service">
            <kendo-grid-messages
              [sortAscending]="getFilterMenuText(column.Type, 'asc')"
              [sortDescending]="getFilterMenuText(column.Type, 'desc')"
            ></kendo-grid-messages>
          </kendo-grid-columnmenu-sort>
        </ng-template>
        <ng-template
          kendoGridFilterMenuTemplate
          let-filter="filter"
          let-filterService="filterService"
        >
          <kendo-grid-sort-filter-menu
            [column]="column"
            [filter]="filter"
            [filterService]="filterService"
          >
            <kendo-grid-messages
              [sortAscending]="getFilterMenuText(column.Type, 'asc')"
              [sortDescending]="getFilterMenuText(column.Type, 'desc')"
            ></kendo-grid-messages>
          </kendo-grid-sort-filter-menu>
          <kendo-multiselect
            style="width: 99%; margin-top: -30px"
            [data]="filterData[column.DataField]"
            textField="display"
            valueField="value"
            [valuePrimitive]="true"
            [value]="filter | filterValues"
            (valueChange)="
              filterValueChange($event, column.DataField, filterService)
            "
            [fillMode]="'outline'"
            placeholder="Filter By..."
          ></kendo-multiselect>
        </ng-template>
        <ng-template
          *ngIf="column.Type === 'integer'"
          kendoGridCellTemplate
          let-dataItem
        >
          {{ dataItem[column.DataField] | number: '1.0-0' }}
        </ng-template>
        <ng-template
          kendoGridGroupHeaderTemplate
          let-group
          let-field="field"
          let-value="value"
          *ngIf="column.Type === 'integer'"
        >
          {{ value | number: '1.0-0' }}
        </ng-template>
        <ng-template
          *ngIf="column.Type === 'perc'"
          kendoGridCellTemplate
          let-dataItem
        >
          {{ dataItem[column.DataField] | percent }}
        </ng-template>
        <ng-template
          kendoGridGroupHeaderTemplate
          let-group
          let-field="field"
          let-value="value"
          *ngIf="column.Type === 'perc'"
        >
          {{ value | percent }}</ng-template
        >

        <ng-template
          *ngIf="column.Type === 'perc2'"
          kendoGridCellTemplate
          let-dataItem
        >
          <ng-container
            *ngIf="
              dataItem[column.DataField] === -9.99 ||
                dataItem[column.DataField] === -999;
              else realData
            "
          >
            N/A
          </ng-container>
          <ng-template #realData>
            {{
              dataItem[column.DataField] === 'N/A' ||
              dataItem[column.DataField] === 'Uncapped'
                ? dataItem[column.DataField]
                : (dataItem[column.DataField] | percent: '1.2')
            }}
          </ng-template>
        </ng-template>
        <ng-template
          kendoGridGroupHeaderTemplate
          let-group
          let-field="field"
          let-value="value"
          *ngIf="column.Type === 'perc2'"
        >
          {{ value | percent: '1.2' }}</ng-template
        >

        <ng-template
          *ngIf="column.Type === 'curr'"
          kendoGridCellTemplate
          let-dataItem
        >
          {{ dataItem[column.DataField] | currency }}
        </ng-template>
        <ng-template
          kendoGridGroupHeaderTemplate
          let-group
          let-field="field"
          let-value="value"
          *ngIf="column.Type === 'curr'"
        >
          {{ value | currency }}</ng-template
        >
      </kendo-grid-column>
    </ng-template>
    <kendo-grid-excel
      fileName="RightBridge-FA.xlsx"
      [fetchData]="excelData"
    ></kendo-grid-excel>
    <ng-template
      kendoPagerTemplate
      let-totalPages="totalPages"
      let-currentPage="currentPage"
    >
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons
        [buttonCount]="buttonCount"
      ></kendo-pager-numeric-buttons>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-page-sizes
        [pageSizes]="pageSizeOptions"
      ></kendo-pager-page-sizes>
    </ng-template>
  </kendo-grid>
  <ngx-loading [show]="loading"></ngx-loading>
</div>
