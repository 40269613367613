<div>
  <h1 class="header col-12 mb-2"> Annuity Book Management </h1>
  @if (!hideGrid) {
    <div class="w-100 controls mb-2">
      <app-grid-filters
        class="col-12"
        (filtersUpdated)="updateFilters($event)"
        [filters]="externalFilters"
        [reset]="resetFilters"
        [preexistingFilters]="filter"
        [manualSearch]="true"
        (triggerSearch)="searchResults()"
        (bridgeIdSearch)="setBridgeIdSearch($event)"
        [bridgeIdOptions]="bridgeIdOptions"
        [bridgeId]="bridgeIdSearch"
        [showBridgeIdSearch]="true"
        [searchButtonText]="'Search Policies'"
      ></app-grid-filters>
      <app-grid-column-filter
        [columns]="gridColumns"
        (columnsUpdated)="externalColumnsUpdated($event)"
      ></app-grid-column-filter>

      <span>
        <button
          mat-flat-button
          color="primary"
          class="ml-2"
          (click)="launchToAW()"
          [disabled]="selectList.length <= 0"
        >
          <mat-icon>rocket_launch</mat-icon>Launch to Annuity Wizard
        </button>
      </span>

      <span>
        <button
          mat-stroked-button
          color="primary"
          class="reset-button"
          (click)="resetGrid()"
        >
          <mat-icon>replay</mat-icon>Reset
        </button>
      </span>
    </div>

    <kendo-grid
      class="col-12"
      #bookManagementGrid="kendoGrid"
      [data]="gridData"
      [skip]="state.skip"
      [pageSize]="state.take"
      [pageable]="true"
      [sortable]="{ allowUnsort: true, mode: 'multiple' }"
      [sort]="state.sort"
      filterable="menu"
      [columnMenu]="{ columnChooser: false }"
      [filter]="state.filter"
      [state]="state"
      [resizable]="true"
      (dataStateChange)="dataStateChange($event)"
      (cellClick)="viewPolicy($event)"
    >
      <kendo-grid-command-column
        width="100"
        title="Actions"
        [columnMenu]="false"
        [sortable]="false"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <mat-checkbox
            matTooltip="Select Annuity"
            (click)="selectListUpdate($event, dataItem)"
            [(ngModel)]="selectChecked[dataItem.ID]"
            [ngModelOptions]="{ standalone: true }"
          >
          </mat-checkbox>
          <button
            mat-icon-button
            class="fs-button"
            (click)="viewPolicy({ dataItem }, true)"
            color="primary"
            matTooltip="View Policy"
          >
            <mat-icon>preview</mat-icon>
          </button>
        </ng-template>
      </kendo-grid-command-column>
      <ng-template ngFor [ngForOf]="gridColumns" let-column>
        <kendo-grid-column
          [field]="column.DataField"
          [hidden]="isHidden(column.DataField)"
          [title]="column.Label"
          [sortable]="column.Sort"
          [filterable]="column.Filter"
          [groupable]="column.Group"
          [media]="column.RespVis"
          [width]="column.Width"
        >
          <ng-template kendoGridColumnMenuTemplate let-service="service">
            <kendo-grid-columnmenu-sort [column]="column" [service]="service">
              <kendo-grid-messages
                [sortAscending]="getFilterMenuText(column.Type, 'asc')"
                [sortDescending]="getFilterMenuText(column.Type, 'desc')"
              ></kendo-grid-messages>
            </kendo-grid-columnmenu-sort>
          </ng-template>
          <ng-template
            kendoGridFilterMenuTemplate
            let-filter="filter"
            let-filterService="filterService"
          >
            <kendo-grid-sort-filter-menu
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
            >
              <kendo-grid-messages
                [sortAscending]="getFilterMenuText(column.Type, 'asc')"
                [sortDescending]="getFilterMenuText(column.Type, 'desc')"
              ></kendo-grid-messages>
            </kendo-grid-sort-filter-menu>
            @switch (column.FilterType) {
              @case ('Dropdown') {
                <kendo-multiselect
                  style="width: 99%; margin-top: -30px"
                  [data]="filterData[column.DataField]"
                  textField="display"
                  valueField="value"
                  [valuePrimitive]="true"
                  [value]="filter | filterValues"
                  (valueChange)="
                    filterValueChange($event, column.DataField, filterService)
                  "
                  [fillMode]="'outline'"
                  placeholder="Filter By..."
                ></kendo-multiselect>
              }
              @case ('Search') {
                <kendo-textbox
                  placeholder="Filter By..."
                  style="width: 99%; margin-top: -30px"
                  [value]="filter | filterValues"
                  (valueChange)="
                    searchFilterValueChange(
                      $event,
                      column.DataField,
                      filterService
                    )
                  "
                  [fillMode]="'outline'"
                ></kendo-textbox>
              }
              @case ('DateRange') {
                <app-date-range-filter
                  [field]="column.DataField"
                  [filter]="filter"
                  [filterService]="filterService"
                  (updateFilter)="
                    dateFilterValueChange($event, column.DataField)
                  "
                ></app-date-range-filter>
              }
            }
          </ng-template>
          @switch (column.Type) {
            @case ('integer') {
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem[column.DataField] | number: '1.0-0' }}
              </ng-template>
              <ng-template
                kendoGridGroupHeaderTemplate
                let-group
                let-field="field"
                let-value="value"
              >
                {{ value | number: '1.0-0' }}
              </ng-template>
            }
            @case ('perc') {
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem[column.DataField] | percent }}
              </ng-template>
              <ng-template
                kendoGridGroupHeaderTemplate
                let-group
                let-field="field"
                let-value="value"
              >
                {{ value | percent }}
              </ng-template>
            }
            @case ('perc2') {
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem[column.DataField] | percent: '1.2' }}
              </ng-template>
              <ng-template
                kendoGridGroupHeaderTemplate
                let-group
                let-field="field"
                let-value="value"
              >
                {{ value | percent: '1.2' }}
              </ng-template>
            }
            @case ('curr') {
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem[column.DataField] | currency }}
              </ng-template>
              <ng-template
                kendoGridGroupHeaderTemplate
                let-group
                let-field="field"
                let-value="value"
              >
                {{ value | currency }}
              </ng-template>
            }
            @case ('date') {
              <ng-template kendoGridCellTemplate let-dataItem>
                {{ dataItem[column.DataField] | date: 'M/d/yyyy' }}
              </ng-template>
            }
          }
          @if (column.DataField === 'notes') {
            <ng-template kendoGridCellTemplate let-dataItem>
              <span [innerHtml]="dataItem[column.DataField]"></span>
            </ng-template>
          }
        </kendo-grid-column>
      </ng-template>
      <ng-template
        kendoPagerTemplate
        let-totalPages="totalPages"
        let-currentPage="currentPage"
      >
        <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
        <kendo-pager-numeric-buttons
          [buttonCount]="buttonCount"
        ></kendo-pager-numeric-buttons>
        <kendo-pager-next-buttons></kendo-pager-next-buttons>
        <kendo-pager-input></kendo-pager-input>
        <kendo-pager-info></kendo-pager-info>
        <kendo-pager-page-sizes
          [pageSizes]="pageSizeOptions"
        ></kendo-pager-page-sizes>
      </ng-template>
    </kendo-grid>
  }
</div>

<ngx-loading [show]="loading"></ngx-loading>
