import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { PostTradeService } from '../../post-trade.service';
import { CaseManagementService } from '../../../case-management/case-management.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import {
  accountInfoKey,
  suitabilityKey,
  accountHoldingsKey,
  recentActivityKey,
  caseHistoryKey,
} from './account-review-detail-keys';
import { scoreClassKey } from '../../trade-review/trade-review-detail/trade-review-detail-keys';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isEmpty } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { AccountHistoryDialogComponent } from './account-history-dialog/account-history-dialog.component';
import { TradeAttachmentsDialogComponent } from '../../trade-review/trade-attachments-dialog/trade-attachments-dialog.component';
import { AccountGroupingDialogComponent } from '../../account-grouping/account-grouping-dialog/account-grouping-dialog.component';

@Component({
  selector: 'app-account-review-detail',
  templateUrl: './account-review-detail.component.html',
  styleUrls: ['./account-review-detail.component.scss'],
})
export class AccountReviewDetailComponent implements OnInit, OnDestroy {
  @Input() accountId;
  rights = [];
  accountDetail;
  accountInfoKey = accountInfoKey;
  suitabilityKey = suitabilityKey;
  accountHoldingsKey = accountHoldingsKey;
  recentActivityKey = recentActivityKey;
  scoreClassKey = scoreClassKey;
  caseHistoryKey = caseHistoryKey;
  isEmpty = isEmpty;
  accountHoldingsRows = 5;
  recentActivityRows = 5;
  caseHistory = [];
  caseHistoryRows = 5;
  reviewCase = false;
  caseChangesMade = false;
  caseData;

  constructor(
    private dialog: MatDialog,
    public fb: FormBuilder,
    public ptSvc: PostTradeService,
    public cms: CaseManagementService,
    public ss: SessionStorageService,
    private route: Router,
    private location: Location,
    private snacky: MatSnackBar
  ) {}

  Highcharts: typeof Highcharts = Highcharts;
  unsubscribe: Subject<any> = new Subject();
  loading = false;

  ngOnInit(): void {
    this.loading = true;
    this.rights = this.ss.get('rights');
    this.ptSvc.getAccountDetail(this.accountId).subscribe(resp => {
      this.accountDetail = resp;
      this.loading = false;
    });

    this.cms
      .getRelatedCases(this.accountId, ['Account', 'AccountGroup'])
      .subscribe(resp => {
        if (resp['length'] > 0) {
          Object.keys(resp).map(index => {
            this.caseHistory.push(resp[index]);
          });
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  returnZero() {
    return 0;
  }

  nav(location) {
    this.route.navigate([`/${location}`]);
  }

  toggleReviewed(reviewStatus) {
    this.loading = true;

    this.ptSvc
      .updateAccount({
        AccountID: [this.accountId],
        ReviewStatus: reviewStatus,
      })
      .subscribe(() => {
        this.ptSvc.getAccountDetail(this.accountId).subscribe(resp => {
          this.snacky.open(`${this.accountId} was updated!`, 'Close', {
            duration: 4000,
          });

          this.accountDetail = resp;
          this.loading = false;
        });
      });
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  initiateCase() {
    this.loading = true;
    const caseData = {
      CaseManagement: {
        Type: 'Account',
        Title: this.accountId,
      },
      CaseToProfile: {
        ProfileID: [this.accountId],
      },
      Client_Rep: {
        RepCode: [this.accountDetail.RepCode],
      },
    };

    this.cms
      .createCaseManagementRecord(this.accountId, caseData)
      .subscribe(resp => {
        this.loading = false;
        this.nav(`case-management/${resp['result']}`);
      });
  }

  seeDocuments() {
    this.dialog.open(TradeAttachmentsDialogComponent, {
      data: {
        tradeId: this.accountId,
        tradeDetail: this.accountDetail,
        profileType: 'account',
      },
      width: '80vw',
      maxWidth: '80vw',
    });
  }

  viewAccountReviewHistory() {
    this.dialog.open(AccountHistoryDialogComponent, {
      data: {
        accountDetail: this.accountDetail,
      },
      width: '80vw',
      maxWidth: '80vw',
    });
  }

  toggleCaseReview(caseItem) {
    this.cms.getCaseManagementProfile(caseItem.CRID).subscribe(() => {
      // Leaving this here in case we want to embed the case manager later/maybe for another configuration?
      // this.caseData = caseItem;
      // this.reviewCase = !this.reviewCase;
      window.open(
        `${this.location.prepareExternalUrl('/case-management/')}${
          caseItem.CRID
        }`,
        '_blank'
      );
    });
  }

  toggleRows(rowProp, value) {
    if (this[rowProp] !== value) {
      this[rowProp] = value;
    } else {
      this[rowProp] = 5;
    }
  }

  changeCaseValue(value) {
    this.caseChangesMade = value;
  }

  accountGrouping() {
    this.dialog.open(AccountGroupingDialogComponent, {
      data: {},
      width: '80vw',
      maxWidth: '80vw',
      autoFocus: false,
    });
  }
}
