import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { PostTradeService } from '../../post-trade.service';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import {
  tradeInfoKey,
  productInfoKey,
  suitabilityKey,
  accountKey,
  tradeHistoryKey,
  tradePositionKey,
  tradeApprovalKey,
  scoreClassKey,
  annuityPositionKey,
  associatedCaseKey,
  productModulesKey,
  caseHistoryKey,
} from './account-grouping-detail-keys';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isEmpty } from 'lodash';
import { CaseManagementService } from '../../../case-management/case-management.service';
import { SessionStorageService } from '../../../services/session-storage.service';
import { MatDialog } from '@angular/material/dialog';
// import { TradeHistoryDialogComponent } from './trade-history-dialog/trade-history-dialog.component';
// import { TradeAttachmentsDialogComponent } from '../trade-attachments-dialog/trade-attachments-dialog.component';
import { AccountGroupingDialogComponent } from '../../account-grouping/account-grouping-dialog/account-grouping-dialog.component';

@Component({
  selector: 'app-account-grouping-detail',
  templateUrl: './account-grouping-detail.component.html',
  styleUrls: ['./account-grouping-detail.component.scss'],
})
export class AccountGroupingDetailComponent implements OnInit, OnDestroy {
  @Input() accountId;
  rights = [];
  tradeDetail;
  caseHistoryKey = caseHistoryKey;
  tradeInfoKey = tradeInfoKey;
  productInfoKey = productInfoKey;
  suitabilityKey = suitabilityKey;
  accountKey = accountKey;
  tradeHistoryKey = tradeHistoryKey;
  tradePositionKey = tradePositionKey;
  tradeApprovalKey = tradeApprovalKey;
  annuityPositionKey = annuityPositionKey;
  associatedCaseKey = associatedCaseKey;
  productModulesKey = productModulesKey;
  scoreClassKey = scoreClassKey;
  isEmpty = isEmpty;
  mutualFundRows = 5;
  tradeHistoryRows = 5;
  productRecommendations = {};
  productModules = [];
  caseHistory = [];
  caseHistoryRows = 5;
  reviewCase = false;
  caseChangesMade = false;
  caseData;

  constructor(
    private dialog: MatDialog,
    public fb: FormBuilder,
    public ptSvc: PostTradeService,
    public cms: CaseManagementService,
    public ss: SessionStorageService,
    private route: Router,
    private location: Location,
    private snacky: MatSnackBar
  ) {}

  Highcharts: typeof Highcharts = Highcharts;
  unsubscribe: Subject<any> = new Subject();
  loading = false;

  ngOnInit(): void {
    // this.loading = true;
    this.rights = this.ss.get('rights');
    this.ptSvc.getAccountGroupingDetail(this.accountId).subscribe(resp => {
      console.log('>>>>>>>', resp);
      // this.tradeDetail = resp;
      // this.sortProductRecommendations(resp['Details']['ProductSelected']);
      this.loading = false;
    });
    // this.cms
    //   .getRelatedCases(this.accountId, ['Trade', 'TradeGroup'])
    //   .subscribe(resp => {
    //     if (resp['length'] > 0) {
    //       Object.keys(resp).map(index => {
    //         this.caseHistory.push(resp[index]);
    //       });
    //     }
    //   });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  sortProductRecommendations(productList) {
    if (productList && productList.length > 0) {
      productList.map(product => {
        if (this.productRecommendations[product.Module]) {
          this.productRecommendations[product.Module].push(product);
        } else {
          this.productModules.push(product.Module);
          this.productRecommendations[product.Module] = [product];
        }
      });
    }
  }

  returnZero() {
    return 0;
  }

  nav(location) {
    this.route.navigate([`/${location}`]);
  }

  toggleApproval(approvalStatus) {
    this.loading = true;
    this.ptSvc
      .updateTrade({
        TradeID: [this.accountId],
        ApprovalStatus: approvalStatus,
      })
      .subscribe(() => {
        this.ptSvc.getPostTradeDetail(this.accountId).subscribe(resp => {
          this.snacky.open(`${this.accountId} was updated!`, 'Close', {
            duration: 4000,
          });

          this.tradeDetail = resp;
          this.loading = false;
        });
      });
  }

  initiateCase() {
    this.loading = true;
    const caseData = {
      CaseManagement: {
        Type: 'Trade',
        Title: this.accountId,
      },
      CaseToProfile: {
        ProfileID: [this.accountId],
      },
      Client_Rep: {
        RepCode: [this.tradeDetail.RepCode],
      },
    };

    this.cms
      .createCaseManagementRecord(this.accountId, caseData)
      .subscribe(resp => {
        this.loading = false;
        this.nav(`case-management/${resp['result']}`);
      });
  }

  downloadFactSheet() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 4000);
  }

  seeDocuments() {
    // this.dialog.open(TradeAttachmentsDialogComponent, {
    //   data: {
    //     tradeId: this.accountId,
    //     tradeDetail: this.tradeDetail,
    //     profileType: 'trade',
    //   },
    //   width: '80vw',
    //   maxWidth: '80vw',
    // });
  }

  viewTradeApprovalHistory() {
    // this.dialog.open(TradeHistoryDialogComponent, {
    //   data: {
    //     tradeDetail: this.tradeDetail,
    //   },
    //   width: '80vw',
    //   maxWidth: '80vw',
    // });
  }

  toggleCaseReview(caseItem) {
    this.cms.getCaseManagementProfile(caseItem.CRID).subscribe(() => {
      // Leaving this here in case we want to embed the case manager later/maybe for another configuration?
      // this.caseData = caseItem;
      // this.reviewCase = !this.reviewCase;
      window.open(
        `${this.location.prepareExternalUrl('/case-management/')}${
          caseItem.CRID
        }`,
        '_blank'
      );
    });
  }

  toggleRows(rowProp, value) {
    if (this[rowProp] !== value) {
      this[rowProp] = value;
    } else {
      this[rowProp] = 5;
    }
  }

  changeCaseValue(value) {
    this.caseChangesMade = value;
  }

  accountGrouping() {
    this.dialog.open(AccountGroupingDialogComponent, {
      data: {},
      width: '80vw',
      maxWidth: '80vw',
      autoFocus: false,
    });
  }
}
