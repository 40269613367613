export const caseHistoryKey = [
  { label: 'Case ID', varName: 'CRID' },
  { label: 'Open Date', varName: 'StatusDate', valueMask: 'date' },
  { label: 'Status', varName: 'StatusName' },
];

export const accountInfoKey = [
  { label: 'Client Name', varName: 'ClientFullName' },
  { label: 'Unit', varName: 'UnitID' },
  { label: 'Account Opened', varName: 'AccountOpenedDate' },
  { label: 'Processed', varName: 'ProcessedDate' },
  {
    label: 'Status',
    varName: 'ReviewStatus',
    key: {
      PEN: 'Pending',
      REV: 'Reviewed',
      REO: 'Reopened',
      tst: 'Test',
    },
  },
  {
    label: 'Account Balance',
    varName: 'AccountBalance',
    valueMask: 'currency',
  },
  { label: 'Registration', varName: 'Registration' },
  { label: 'Associated Rep Code', varName: 'RepCode' },
];
export const scoreClassKey = {
  Positive: 'check_circle',
  Warning: 'error',
  Negative: 'remove_circle',
};
export const suitabilityKey = [
  {
    label: 'Entity Type',
    varSet: 'Client',
    varName: 'Type',
    key: {
      P: 'Person',
      E: 'Entity',
      O: 'Trust',
    },
  },
  { label: 'Nature', varSet: 'TradeAccount', varName: 'NatureCode' },
  { label: 'Age', varSet: 'ClientEstimates', varName: 'Age' },
  {
    label: 'Employment Status',
    varSet: 'ClientPerson',
    varName: 'EmploymentStatus',
    key: {
      NA: 'Unknown',
      U: 'Unemployed',
      FT: 'Full Time',
      PT: 'Part Time',
      RET: 'Retired',
      SE: 'Self Employed',
      HM: 'Home Maker',
      ST: 'Student',
      EMP: 'Employed',
    },
  },
  {
    label: 'Marital Status',
    varSet: 'ClientPerson',
    varName: 'MaritalStatus',
    key: {
      M: 'Married',
      S: 'Single',
    },
  },
  { label: 'State', varSet: 'ClientPerson', varName: 'State' },
  {
    label: 'Gender',
    varSet: 'ClientPerson',
    varName: 'Gender',
    key: {
      M: 'Male',
      F: 'Female',
    },
  },
  {
    label: 'NetWorth',
    varSet: 'ClientFinancial',
    varName: 'LiquidNetWorth',
    valueMask: 'currency',
  },
  {
    label: 'Tax Bracket',
    varSet: 'ClientFinancial',
    varName: 'TaxBracket',
  },
  { label: 'Objective', varSet: 'Client', varName: 'InvestmentObjective' },
  { label: 'Risk Tolerance', varSet: 'Client', varName: 'RiskTolerance' },
];
export const accountHoldingsKey = [
  { label: 'Product Type', varName: 'SecType' },
  { label: 'Symbol/Code', varName: 'Symbol' },
  { label: 'Name', varName: 'Name' },
  { label: 'Carrier', varName: 'Carrier' },
  { label: 'Last Purchase', varName: 'UpdateDate', valueMask: 'date' },
  { label: '# Shares', varName: 'Quantity' },
  { label: 'Current Value', varName: 'CurrentValue', valueMask: 'currency' },
];
export const recentActivityKey = [
  { label: 'Score Class', varName: 'ScoreClass' },
  { label: 'Activity Date', varName: 'TradeDate', valueMask: 'date' },
  { label: 'Product Type', varName: 'ProductType' },
  { label: 'Symbol', varName: 'Symbol' },
  { label: 'Product Name', varName: 'ProductType' },
  { label: 'Amount', varName: 'Amount', valueMask: 'currency' },
  { label: 'Commission', varName: 'CommissionDollars', valueMask: 'currency' },
];
export const accountReviewKey = [
  {
    label: 'Processed Date',
    varName: 'ProcessedDate',
  },
  {
    label: 'Score',
    varName: 'ScoreClass',
  },
  {
    label: 'Status',
    varName: 'ReviewStatus',
    key: {
      PEN: 'Pending',
      REV: 'Reviewed',
      REO: 'Reopened',
      tst: 'Test',
    },
  },
];
