<div mat-dialog-content class="dialog-container annuity-lookup">
  <h1 class="w-100" *ngIf="!dtccEnabled || (dtccEnabled && !noShowBeacon)">
    Select a Product
  </h1>
  <h1 class="w-100" *ngIf="dtccEnabled && startDtcc">Annuity Policy Search</h1>

  <!--  -->
  <!-- Begin DTCC -->
  <!--  -->
  <div class="col-12 mt-5" *ngIf="dtccEnabled && showAdvisorQuestion">
    <p class="section text-center w-100 mb-3">
      Is the Financial Professional the Agent of Record on the Annuity Policy?
    </p>
    <div class="advisor-button-container w-100">
      <button mat-raised-button (click)="triggerLookup(true)">
        <div>
          <h3>Yes</h3>
        </div>
        <div class="sub-button"> Look up Existing Policy </div>
      </button>
      <button mat-raised-button (click)="triggerLookup(false)">
        <div>
          <h3>No</h3>
        </div>
        <div class="sub-button"> Historical Annuity Lookup </div>
      </button>
    </div>
  </div>

  <div class="col-12 row mt-3" *ngIf="dtccEnabled && startDtcc">
    <div class="col-12 pl-0" *ngIf="dtccWindowClosed">
      <p class="warning">
        This functionality may be down for weekly maintenance and will be
        available after 2pm EST Sunday
      </p>
    </div>
    @if (homeOfficeUser && autoPopulateNPN) {
      <mat-form-field class="col-10 pl-0">
        <mat-label>Select a Rep</mat-label>
        <input matInput [matAutocomplete]="repAuto" [formControl]="repCtrl" />
        <mat-autocomplete
          #repAuto="matAutocomplete"
          (optionSelected)="selectRep($event)"
          [displayWith]="displayReps"
        >
          @if (repDropdownNoResults) {
            <mat-option disabled>{{ repDropdownMessage }}</mat-option>
          } @else {
            <mat-option *ngFor="let rep of filteredReps" [value]="rep">{{
              rep.name
            }}</mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
      <button
        [disabled]="!selectedRep || simpleLoading"
        class="col-2 search-button"
        mat-raised-button
        color="accent"
        (click)="searchRepForNPNs()"
      >
        <mat-icon>search</mat-icon> Search
      </button>
      @if (repMultiSelectSubtitle) {
        <div class="col-12 pl-0">
          <p>{{ repMultiSelectSubtitle }}</p>
        </div>
      }
    }
    @if (autoPopulateNPN && searchUserID) {
      <mat-form-field
        [ngClass]="NPNs && NPNs.length === 1 ? 'col-12' : 'col-10'"
        class="pl-0"
      >
        <mat-label>Rep Name - National Producer Number (NPN)</mat-label>
        <mat-select
          #NPNDropdown
          [disabled]="simpleLoading"
          [(ngModel)]="producerNum"
        >
          @for (npn of NPNs; track npn) {
            <mat-option [value]="npn.NPN"
              >{{ npn.Name }} - {{ npn.NPN }}</mat-option
            >
          }
        </mat-select>
        <mat-hint>{{ DTCCNotePrompt }}</mat-hint>
      </mat-form-field>
    }
    @if (!autoPopulateNPN) {
      <mat-form-field class="col-10 pl-0">
        <mat-label>National Insurance Producer Number</mat-label>
        <input
          [disabled]="simpleLoading"
          matInput
          placeholder="Producer Number"
          [(ngModel)]="producerNum"
        />
        <mat-hint>{{ DTCCNotePrompt }}</mat-hint>
      </mat-form-field>
    }
    @if (
      !autoPopulateNPN ||
      (autoPopulateNPN &&
        searchUserID &&
        (!NPNs || (NPNs && NPNs.length !== 1)))
    ) {
      <button
        [disabled]="dtccWindowClosed || !producerNum || simpleLoading"
        class="col-2 search-button"
        mat-raised-button
        color="accent"
        (click)="submitOrSearchProducerNumber()"
      >
        <mat-icon>{{ producerNumBtnIcon }}</mat-icon> {{ producerNumBtnTxt }}
      </button>
    }
    @if (!autoPopulateNPN) {
      <div class="col-12 pl-0">
        <p>
          Don't know your National Producer Number (NPN)? Look it up
          <a href="https://nipr.com/help/look-up-your-npn" target="_blank"
            >here</a
          >.
        </p>
      </div>
    }
  </div>

  <div class="col-12 row mt-3" *ngIf="dtccEnabled && submittedProducerNum">
    <mat-form-field class="col-10 pl-0">
      <mat-label>Policy Number</mat-label>
      <input matInput [disabled]="simpleLoading" [(ngModel)]="policyNum" />
    </mat-form-field>
    <button
      mat-raised-button
      class="col-2 search-button"
      color="accent"
      (click)="searchDtcc()"
      [disabled]="!(policyNum && producerNum) || simpleLoading"
    >
      <mat-icon>search</mat-icon> Search
    </button>
  </div>

  <div
    class="col-12 row grid-table mt-5 justify-content-start"
    *ngIf="dtccEnabled && ogData && ogData.length > 0 && !simpleLoading"
  >
    @if (ogData && ogData.length > 1) {
      <h5 class="col-12">Filter Results</h5>
      <mat-form-field class="col-auto">
        <mat-label>Policy Number</mat-label>
        <input
          matInput
          #policyNumFilter
          (input)="
            filterValueChange(policyNumFilter.value, 'insurancePolicyPolNumber')
          "
        />
      </mat-form-field>
      <mat-form-field class="col-auto">
        <mat-label>Name</mat-label>
        <input
          matInput
          #fullNameFilter
          (input)="
            filterValueChange(
              fullNameFilter.value,
              'insurancePolicyPartyFullName'
            )
          "
        />
      </mat-form-field>
      <mat-form-field class="col-auto">
        <mat-label>Product Name</mat-label>
        <input
          matInput
          #productNameFilter
          (input)="
            filterValueChange(
              productNameFilter.value,
              'insuranceProductCusipName'
            )
          "
        />
      </mat-form-field>
      <mat-form-field class="col-auto">
        <mat-label>Product Type</mat-label>
        <input
          matInput
          #productTypeFilter
          (input)="
            filterValueChange(
              productTypeFilter.value,
              'insuranceProductTypeCode'
            )
          "
        />
      </mat-form-field>
    }

    @if (gridData && gridData.length > 0) {
      <table class="w-100">
        <thead>
          <th></th>
          <th>Policy Number</th>
          <th>Name</th>
          <th>Product Name</th>
          <th>Product Type</th>
          <th>Contract Value</th>
        </thead>
        <tbody>
          <tr *ngFor="let row of gridData" (click)="setPolicyDetails(row)">
            <td class="selected-policy"
              ><mat-icon
                *ngIf="
                  row.insurancePolicyPolNumber ===
                  selectedPolicyDetails?.insurancePolicyPolNumber
                "
                >check_circle</mat-icon
              ></td
            >
            <td>{{ row.insurancePolicyPolNumber }}</td>
            <td>
              {{ row.insurancePolicyPartyFirstName }}
              {{ row.insurancePolicyPartyLastName }}
            </td>
            <td>{{ row.insuranceProductCusipName }}</td>
            <td>{{ row.insuranceProductTypeCode }}</td>
            <td>{{ row.insurancePolicyContractValueAmount | currency }}</td>
          </tr>
        </tbody>
      </table>
    }
  </div>

  <div
    class="col-12 error-container mt-5 mx-auto"
    *ngIf="dtccEnabled && noResults && !simpleLoading"
  >
    <h3 class="text-center w-100 error-message">
      {{ errorMessage }}
    </h3>
  </div>

  @if (simpleLoading) {
    <div class="col-12 mt-5 mx-auto">
      <app-simple-loading [message]="simpleLoadingMessage"></app-simple-loading>
    </div>
  }

  <!--  -->
  <!-- Begin Beacon -->
  <!--  -->
  <div
    class="col-12 row"
    *ngIf="!dtccEnabled || (dtccEnabled && !noShowBeacon)"
  >
    <form class="col-12 col-md-7" [formGroup]="form" (ngSubmit)="saveData()">
      <mat-form-field class="col-12">
        <mat-label>Annuity Purchase Date</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          [placeholder]="'Annuity Purchase Date'"
          formControlName="dateCtrl"
          (dateChange)="setPurchDate($event)"
          [max]="maxDate"
          required
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        @if (form.controls.dateCtrl.errors) {
          <mat-error>This field is required</mat-error>
        }
      </mat-form-field>

      <mat-form-field class="col-12">
        <mat-label>Select a Carrier</mat-label>
        <input
          type="text"
          placeholder="Select a Carrier"
          aria-label="Carrier"
          matInput
          formControlName="compCtrl"
          [matAutocomplete]="carrierSelect"
        />
        <mat-autocomplete
          #carrierSelect="matAutocomplete"
          (optionSelected)="getProducts($event)"
          [displayWith]="displayComp"
        >
          @for (opt of filteredCompanies | async; track opt) {
            <mat-option
              [value]="opt"
              matTooltip="{{ opt.alternate_name }}"
              matToolTipPosition="before"
              matTooltipShowDelay="750"
              >{{ opt.alternate_name }}</mat-option
            >
          }
        </mat-autocomplete>
        @if (form.controls.compCtrl.errors) {
          <mat-error>This field is required</mat-error>
        }
      </mat-form-field>
      <mat-form-field
        class="col-12"
        [ngClass]="{ 'col-12': !canSelectClass, 'col-8': canSelectClass }"
        *ngIf="!noProductsReturned"
      >
        <mat-label>Select an Annuity</mat-label>
        <input
          type="text"
          placeholder="Select an Annuity"
          aria-label="Annuity"
          matInput
          formControlName="prodCtrl"
          [matAutocomplete]="annuitySelect"
        />
        <mat-autocomplete
          #annuitySelect="matAutocomplete"
          (optionSelected)="selectProduct($event)"
          [displayWith]="displayProd"
        >
          @for (opt of filteredProducts | async; track opt) {
            <mat-option
              [value]="opt"
              matTooltip="{{ opt.product_name }}"
              matToolTipPosition="before"
              matTooltipShowDelay="750"
              >{{ opt.product_name }}</mat-option
            >
          }
        </mat-autocomplete>
        @if (form.controls.prodCtrl.errors) {
          <mat-error>This field is required</mat-error>
        }
      </mat-form-field>
      <p *ngIf="noProductsReturned" class="error small noProds">
        No annuity contract information is available for this carrier based on
        the purchase date provided
      </p>
      <p *ngIf="noProductDetails" class="error small noDetails">
        No annuity contract information is currently available for this product
      </p>
      <mat-form-field class="col-4" *ngIf="canSelectClass">
        <mat-label>Select a Share Class</mat-label>
        <mat-select
          placeholder="Share Class"
          class="w-100"
          (selectionChange)="selectShareClass($event)"
          formControlName="shareClassCtrl"
          required
        >
          <mat-option *ngFor="let opt of shareClassOptions" [value]="opt.value">
            {{ opt.display }}
          </mat-option>
        </mat-select>
        <mat-error>This field is required</mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="canSelectMande" class="col-12">
        <mat-label>Select Annual Charges</mat-label>
        <mat-select
          formControlName="prodMandeCtrl"
          (selectionChange)="selectMandE($event)"
        >
          <mat-option
            class="radio-button"
            *ngFor="let opt of mandeOptions"
            [value]="opt.value"
          >
            {{ opt.display }}
          </mat-option>
        </mat-select>
        <mat-error>This field is required</mat-error>
      </mat-form-field>
      <div class="death-container">
        <p class="col-12 mt-2" *ngIf="canSelectDeath">
          Death Benefit on Contract
        </p>
        <mat-form-field class="col-12" *ngIf="canSelectDeath">
          <mat-label>Select a Death Benefit</mat-label>
          <mat-select
            placeholder="Select a Death Benefit"
            class="w-100"
            (selectionChange)="selectRider($event, 'death')"
            formControlName="deathRiderCtrl"
            required
          >
            <mat-option *ngFor="let opt of deathRiders" [value]="opt.Key">
              {{ opt.Name }}
            </mat-option>
          </mat-select>
          <mat-error>This field is required</mat-error>
        </mat-form-field>

        <mat-form-field
          class="col-12"
          *ngIf="charges['death'] && charges['death'].length > 0"
        >
          <mat-label>Select Charges</mat-label>
          <mat-select
            placeholder="Select Charges"
            class="w-100"
            name="deathChargesCtrl"
            formControlName="deathChargesCtrl"
            (selectionChange)="selectCharges($event, 'death')"
            required
          >
            <mat-option
              *ngFor="let opt of charges['death']"
              [value]="opt.value"
            >
              {{ opt.display }}
            </mat-option>
          </mat-select>
          <mat-error>This field is required</mat-error>
        </mat-form-field>

        <mat-form-field class="col-12" *ngIf="canSelectCases">
          <mat-label>Select a Case</mat-label>
          <mat-select
            placeholder="Select a Case"
            class="w-100"
            name="casesCtrl"
            formControlName="casesCtrl"
            (selectionChange)="selectCase($event)"
            required
          >
            <mat-option *ngFor="let opt of cases" [value]="opt.value">
              {{ opt.display }}
            </mat-option>
          </mat-select>
          <mat-error>This field is required</mat-error>
        </mat-form-field>
      </div>
      <div class="living-container">
        <p class="col-12 mt-2" *ngIf="canSelectLiving">
          Client Purchased Living Benefit
        </p>
        <mat-radio-group
          class="radio-group col-12"
          [ngClass]="{
            'mat-error':
              form.controls.purchasedLivingCtrl.errors &&
              !form.controls.purchasedLivingCtrl.untouched
          }"
          *ngIf="canSelectLiving"
          formControlName="purchasedLivingCtrl"
          (change)="benefitChange($event, 'living')"
          required
        >
          <mat-radio-button class="radio-button" value="true">
            Yes
          </mat-radio-button>
          <mat-radio-button class="radio-button" value="false">
            No
          </mat-radio-button>
          <mat-error
            *ngIf="
              form.controls.purchasedLivingCtrl.errors &&
              !form.controls.purchasedLivingCtrl.untouched
            "
            class="mat-error"
            style="font-size: 75%"
          >
            This field is required
          </mat-error>
        </mat-radio-group>
        <mat-form-field
          class="col-12"
          *ngIf="
            canSelectLiving &&
            form.controls.purchasedLivingCtrl.value === 'true'
          "
        >
          <mat-label>Select a Living Benefit</mat-label>
          <mat-select
            placeholder="Select a Living Benefit"
            class="w-100"
            (selectionChange)="selectRider($event, 'living')"
            formControlName="livingRiderCtrl"
            required
          >
            <mat-option *ngFor="let opt of livingRiders" [value]="opt.Key">
              {{ opt.Name }}
            </mat-option>
          </mat-select>
          <mat-error>This field is required</mat-error>
        </mat-form-field>

        <p class="col-12" *ngIf="canSelectJoint">Single or Joint Account</p>
        <mat-radio-group
          class="radio-group col-12"
          [ngClass]="{
            'mat-error':
              form.controls.singleJointBandCtrl.errors &&
              !form.controls.singleJointBandCtrl.untouched
          }"
          *ngIf="canSelectBands"
          name="singleJointBandCtrl"
          formControlName="singleJointBandCtrl"
          value="single_life"
          (change)="selectBand()"
          required
        >
          <mat-radio-button class="radio-button" value="single_life" required>
            Single
          </mat-radio-button>
          <mat-radio-button class="radio-button" value="joint_life" required>
            Joint
          </mat-radio-button>
          <mat-error
            *ngIf="
              form.controls.singleJointBandCtrl.errors &&
              !form.controls.singleJointBandCtrl.untouched
            "
            class="mat-error"
            style="font-size: 75%"
          >
            This field is required
          </mat-error>
        </mat-radio-group>
        <mat-form-field class="col-12" *ngIf="canSelectBandOption">
          <mat-label>Select a Withdrawal Option</mat-label>
          <mat-select
            placeholder="Select a Withdrawal Option"
            class="w-100"
            name="bandOptionCtrl"
            formControlName="bandOptionCtrl"
            (selectionChange)="selectBand('withdrawal')"
            required
          >
            <mat-option *ngFor="let opt of ageBandOptions" [value]="opt.value">
              {{ opt.display }}
            </mat-option>
          </mat-select>
          <mat-error>This field is required</mat-error>
        </mat-form-field>
        <mat-form-field class="col-12" *ngIf="canSelectBands">
          <mat-label>Select an Age Band</mat-label>
          <mat-select
            placeholder="Select an Age Band"
            class="w-100"
            name="bandCtrl"
            formControlName="bandCtrl"
            (selectionChange)="selectBand('ageBand')"
            required
          >
            <mat-option *ngFor="let opt of ageBands" [value]="opt.value">
              {{ opt.display }}
            </mat-option>
          </mat-select>
          <mat-error>This field is required</mat-error>
        </mat-form-field>
        <mat-form-field
          class="col-12"
          *ngIf="rollups['living'] && rollups['living'].length > 0"
        >
          <mat-label>Select a Roll Up</mat-label>
          <mat-select
            placeholder="Select a Roll Up"
            class="w-100"
            name="livingRollupCtrl"
            formControlName="livingRollupCtrl"
            (selectionChange)="selectRollup($event, 'living')"
            required
          >
            <mat-option
              *ngFor="let opt of rollups['living']"
              [value]="opt.value"
            >
              {{ opt.display }}
            </mat-option>
          </mat-select>
          <mat-error>This field is required</mat-error>
        </mat-form-field>
        <mat-form-field
          class="col-12"
          *ngIf="livingChargesOptions && livingChargesOptions.length > 0"
        >
          <mat-label>Select Charges</mat-label>
          <mat-select
            placeholder="Select Charges"
            class="w-100"
            name="livingChargesCtrl"
            formControlName="livingChargesCtrl"
            (selectionChange)="selectCharges($event, 'living')"
            required
          >
            <mat-option
              *ngFor="let opt of livingChargesOptions"
              [value]="opt.value"
            >
              {{ opt.display }}
            </mat-option>
          </mat-select>
          <mat-error>This field is required</mat-error>
        </mat-form-field>
      </div>
      <div class="accumulation-container" *ngIf="canSelectAccumulation">
        <p class="col-12 mt-2">Client Purchased Accumulation Benefit</p>
        <mat-radio-group
          class="radio-group col-12"
          [ngClass]="{
            'mat-error':
              form.controls.purchasedAccumulationCtrl.errors &&
              !form.controls.purchasedAccumulationCtrl.untouched
          }"
          *ngIf="canSelectAccumulation"
          formControlName="purchasedAccumulationCtrl"
          (change)="benefitChange($event, 'accumulation')"
          required
        >
          <mat-radio-button class="radio-button" value="true" required>
            Yes
          </mat-radio-button>
          <mat-radio-button class="radio-button" value="false" required>
            No
          </mat-radio-button>
          <mat-error
            *ngIf="
              form.controls.purchasedAccumulationCtrl.errors &&
              !form.controls.purchasedAccumulationCtrl.untouched
            "
            class="mat-error"
            style="font-size: 75%"
          >
            This field is required
          </mat-error>
        </mat-radio-group>
        <mat-form-field
          class="col-12"
          *ngIf="
            canSelectAccumulation &&
            form.controls.purchasedAccumulationCtrl.value === 'true'
          "
        >
          <mat-label>Select a Accumulation Benefit</mat-label>
          <mat-select
            placeholder="Select an Accumulation Benefit"
            class="w-100"
            (selectionChange)="selectRider($event, 'accumulation')"
            formControlName="accumulationRiderCtrl"
            required
          >
            <mat-option
              *ngFor="let opt of accumulationRiders"
              [value]="opt.Key"
            >
              {{ opt.Name }}
            </mat-option>
          </mat-select>
          <mat-error>This field is required</mat-error>
        </mat-form-field>
        <mat-form-field
          class="col-12"
          *ngIf="charges['accumulation'] && charges['accumulation'].length > 0"
        >
          <mat-label>Select Charges</mat-label>
          <mat-select
            placeholder="Select Charges"
            class="w-100"
            name="accumulationChargesCtrl"
            formControlName="accumulationChargesCtrl"
            (selectionChange)="selectCharges($event, 'accumulation')"
            required
          >
            <mat-option
              *ngFor="let opt of charges['accumulation']"
              [value]="opt.value"
            >
              {{ opt.display }}
            </mat-option>
          </mat-select>
          <mat-error>This field is required</mat-error>
        </mat-form-field>
      </div>
    </form>

    <div class="col-12 col-md-5 placeholder" *ngIf="!showData">
      <div><strong class="w-25"></strong> <span class="w-50"></span></div>
      <div><strong class="w-10"></strong> <span class="w-75"></span></div>
      <div><strong class="w-15"></strong> <span class="w-33"></span></div>
      <div><strong class="w-10"></strong> <span class="w-50"></span></div>
      <div><strong class="w-33"></strong> <span class="w-33"></span></div>
      <div><strong class="w-15"></strong> <span class="w-33"></span></div>

      <div><strong class="w-33"></strong> <span class="w-50"></span></div>
      <div><strong class="w-15"></strong> <span class="w-33"></span></div>
      <div><strong class="w-25"></strong> <span class="w-33"></span></div>
      <div><strong class="w-10"></strong> <span class="w-25"></span></div>
      <div><strong class="w-55"></strong> <span class="w-33"></span></div>
      <div><strong class="w-33"></strong> <span class="w-15"></span></div>
    </div>
    <div class="col-12 col-md-5 dataDisplay" *ngIf="showData">
      <h4>Product Information</h4>
      <div *ngIf="exchangeData[this.getVarname('_Carrier')]">
        <strong>Carrier:</strong>
        {{ exchangeData[this.getVarname('_Carrier')] }}
      </div>
      <div *ngIf="exchangeData[this.getVarname('_PlanName')]">
        <strong>Product:</strong>
        {{ exchangeData[this.getVarname('_PlanName')] }}
      </div>
      <div *ngIf="exchangeData[this.getVarname('_DisplayShareClass')]">
        <strong>Share Class:</strong>
        {{ exchangeData[this.getVarname('_DisplayShareClass')] }}
      </div>
      <div *ngIf="exchangeData[this.getVarname('_HistoricalFee')]">
        <strong>Product M&amp;E:</strong>
        {{
          exchangeData[this.getVarname('_BrokeragePercentageFee')]
            | percent: '1.2-4'
        }}
      </div>
      <div *ngIf="exchangeData[this.getVarname('_CDSCSchedule')]">
        <strong>CDSC Schedule:</strong>
        {{ exchangeData[this.getVarname('_CDSCSchedule')] }}
      </div>
      <div *ngIf="exchangeData[this.getVarname('_RidersAvailable')]">
        <strong>Riders Available:</strong>
        {{ exchangeData[this.getVarname('_RidersAvailable')] }}
      </div>
      <div
        *ngIf="
          exchangeData[this.getVarname('_HasWithdrawalBenefit')] &&
          exchangeData[this.getVarname('_HasWithdrawalBenefit')] !== 'N'
        "
      >
        <strong>Withdrawal Benefit:</strong>
        {{
          +exchangeData[this.getVarname('_HasWithdrawalBenefit')] / 100
            | percent: '1.2-4'
        }}
      </div>

      <h4 *ngIf="exchangeData[this.getVarname('_LivingBenefitName')]">
        Living Benefit Rider Details:
      </h4>
      <div *ngIf="exchangeData[this.getVarname('_LivingBenefitName')]">
        <strong>Name:</strong>
        {{ exchangeData[this.getVarname('_LivingBenefitName')] }}
      </div>
      <div *ngIf="exchangeData[this.getVarname('_HistoricalLBDescription')]">
        <strong>Description:</strong>
        {{ exchangeData[this.getVarname('_HistoricalLBDescription')] }}
      </div>
      <div *ngIf="exchangeData[this.getVarname('_HistoricalLBOtherInfo')]">
        <strong>Other Information:</strong>
        {{ exchangeData[this.getVarname('_HistoricalLBOtherInfo')] }}
      </div>
      <div
        *ngIf="exchangeData[this.getVarname('_HistoricalLBBenefitBaseGeneral')]"
      >
        <strong>Base:</strong>
        {{ exchangeData[this.getVarname('_HistoricalLBBenefitBaseGeneral')] }}
      </div>
      <div
        *ngIf="exchangeData[this.getVarname('_HistoricalLBStateExceptions')]"
      >
        <strong>State Exceptions:</strong>
        {{ exchangeData[this.getVarname('_HistoricalLBStateExceptions')] }}
      </div>
      <div *ngIf="exchangeData[this.getVarname('_RiderMandE')]">
        <strong>Charges:</strong>
        {{ exchangeData[this.getVarname('_RiderMandE')] }}
      </div>
      <div
        *ngIf="exchangeData[this.getVarname('_HistoricalLBWithdrawalPercent')]"
      >
        <strong>Withdrawal Percent:</strong>
        {{ exchangeData[this.getVarname('_HistoricalLBWithdrawalPercent')] }}
      </div>
      <div
        *ngIf="exchangeData[this.getVarname('_HistoricalLBRollUpsAvailable')]"
      >
        <strong>Roll Ups Available:</strong>
        {{ exchangeData[this.getVarname('_HistoricalLBRollUpsAvailable')] }}
      </div>
      <div *ngIf="exchangeData[this.getVarname('_DeferralBonus')]">
        <strong>Selected Roll Up:</strong>
        {{
          +exchangeData[this.getVarname('_DeferralBonus')] | percent: '1.2-4'
        }}
      </div>
      <div *ngIf="exchangeData[this.getVarname('_HistoricalLBDeferralLength')]">
        <strong>Selected Roll Up Deferral Length:</strong>
        {{
          exchangeData[this.getVarname('_HistoricalLBDeferralLength')]
            | number: '1.0-1'
        }}
        Years
      </div>

      <h4 *ngIf="exchangeData[this.getVarname('_GMABName')]">
        Accumulation Rider Details:
      </h4>
      <div *ngIf="exchangeData[this.getVarname('_GMABName')]">
        <strong>Name:</strong> {{ exchangeData[this.getVarname('_GMABName')] }}
      </div>
      <div *ngIf="exchangeData[this.getVarname('_GMABRiderMandE')]">
        <strong>Charges:</strong>
        {{ exchangeData[this.getVarname('_GMABRiderMandE')] }}
      </div>
      <div *ngIf="exchangeData[this.getVarname('_GMABNotes')]">
        <strong>Notes:</strong>
        <span [innerHTML]="exchangeData[this.getVarname('_GMABNotes')]"></span>
      </div>

      <h4 *ngIf="exchangeData[this.getVarname('_DeathBenefitName')]">
        Death Benefit Rider Details:
      </h4>
      <div *ngIf="exchangeData[this.getVarname('_DeathBenefitName')]">
        <strong>Name:</strong>
        {{ exchangeData[this.getVarname('_DeathBenefitName')] }}
      </div>
      <div *ngIf="exchangeData[this.getVarname('_HistoricalDBDescription')]">
        <strong>Description:</strong>
        {{ exchangeData[this.getVarname('_HistoricalDBDescription')] }}
      </div>
      <div
        *ngIf="exchangeData[this.getVarname('_HistoricalDBBenefitBaseGeneral')]"
      >
        <strong>Base:</strong>
        {{ exchangeData[this.getVarname('_HistoricalDBBenefitBaseGeneral')] }}
      </div>
      <div *ngIf="exchangeData[this.getVarname('_HistoricalDBOtherInfo')]">
        <strong>Base:</strong>
        {{ exchangeData[this.getVarname('_HistoricalDBOtherInfo')] }}
      </div>
      <div
        *ngIf="
          exchangeData[this.getVarname('_HistoricalDBSpousalContinuation')]
        "
      >
        <strong>Spousal Protection:</strong>
        {{ exchangeData[this.getVarname('_HistoricalDBSpousalContinuation')] }}
      </div>
      <div
        *ngIf="exchangeData[this.getVarname('_HistoricalDBCancellationOption')]"
      >
        <strong>Charges:</strong>
        {{ exchangeData[this.getVarname('_HistoricalDBCancellationOption')] }}
      </div>
      <div *ngIf="exchangeData[this.getVarname('_DBBasisCost')]">
        <strong>Charge:</strong>
        {{ exchangeData[this.getVarname('_DBBasisCost')] }}
      </div>
      <div *ngIf="exchangeData[this.getVarname('_HistoricalDBFeeAccount')]">
        <strong>Charge Basis:</strong>
        {{ exchangeData[this.getVarname('_HistoricalDBFeeAccount')] }}
      </div>
      <div
        *ngIf="exchangeData[this.getVarname('_HistoricalDBFeeCalcFrequency')]"
      >
        <strong>Charge Frequency:</strong>
        {{ exchangeData[this.getVarname('_HistoricalDBFeeCalcFrequency')] }}
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions class="row">
  <button
    mat-button
    [disabled]="simpleLoading"
    (click)="stepBack()"
    *ngIf="(dtccEnabled && startDtcc) || (dtccEnabled && !noShowBeacon)"
  >
    <mat-icon>arrow_back</mat-icon>Back
  </button>
  <div class="buttons">
    <button
      mat-button
      (click)="dlFactSheet(); $event.preventDefault()"
      [disabled]="!selectedProduct.product_key"
      color="primary"
      *ngIf="!dtccEnabled || (dtccEnabled && !noShowBeacon)"
    >
      <mat-icon>insert_drive_file</mat-icon>Download Fact Sheet
    </button>
    <span>
      <button
        mat-button
        color="warn"
        [disabled]="simpleLoading"
        (click)="close(); $event.preventDefault()"
      >
        Cancel
      </button>
      <button
        mat-raised-button
        color="accent"
        (click)="getPolicyDetails()"
        [disabled]="!selectedPolicyDetails || simpleLoading"
        *ngIf="dtccEnabled && gridData"
      >
        <mat-icon>check</mat-icon>Use This One
      </button>
      <button
        mat-raised-button
        color="accent"
        *ngIf="!dtccEnabled || (dtccEnabled && !noShowBeacon)"
        (click)="saveData()"
      >
        Save Annuity Data
      </button>
    </span>
  </div>
</div>
<ngx-loading
  [show]="loading || loadingSignal()"
  [config]="{ fullScreenBackdrop: false }"
></ngx-loading>
