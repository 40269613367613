<div class="page-container account-review-container container">
  <div class="breadcrumb">
    <button
      mat-button
      (click)="nav('unified')"
      *hasPermission="'!ToolsLandingPage'"
    >
      <mat-icon>home</mat-icon>
      <span class="text-container">Home</span>
    </button>
    <button
      mat-button
      (click)="nav('tools')"
      *hasPermission="'ToolsLandingPage'"
    >
      <mat-icon>home</mat-icon>
      <span class="text-container">Home</span>
    </button>
    /
    <button mat-button (click)="nav('post-trade/AR')">
      <span class="text-container">Post Transaction</span>
    </button>
    /
    <button
      mat-button
      (click)="nav('account-review')"
      [disabled]="accountId === ''"
    >
      <span class="text-container">Account Monitor</span>
    </button>
    @if (accountId !== '') {
      <span>/</span>
      <button
        mat-button
        (click)="nav('account-review')"
        [disabled]="accountId !== ''"
      >
        <span class="text-container">Account Detail</span>
      </button>
    }
  </div>

  @if (accountId === '') {
    <app-account-review-grid></app-account-review-grid>
  } @else {
    <app-account-review-detail
      [accountId]="accountId"
    ></app-account-review-detail>
  }
</div>
